import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Layout, Card, InputNumber, Button } from 'antd';
import { ShoppingCartOutlined, DeleteFilled } from '@ant-design/icons';
import { sumBy } from 'lodash';

import basketTool from '../utils/basketTool';
import Events from '../utils/events';
import loop54Client from '../utils/loop54Client';
import DemoWrapper from '../DemoWrapper';
import Header from '../components/header/Header';
import ResultItem from '../components/results/ResultItem';
import Footer from '../components/footer';

import './Checkout.css';

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recItems: null
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.shoppingbasket !== prevProps.shoppingbasket) {
      this.getBasketRecommendations();
    }
  }

  componentDidMount() {
    this.getBasketRecommendations();
  }

  handleProductClick = (item) => {
    const { config } = this.props;
    let event = Events.buildEventObj(item.id, this.props.config.clickEventType);
    Events.send([event], config.url).then(() => {
      this.context.router.history.push(item.productLink)
    });
  }

  handleSearch = (item) => {
    var redirect = '/' + this.props.demoName + '/search?q=' + item.value;
    if (item.facet) { redirect = redirect + '&f=' + item.facet }
    this.context.router.history.push(redirect)
  }

  onAmountChange = (val, item) => {
    basketTool.updateBasketItemAmount(item, val, this.props.demoName, this.props.onBasketChange)
  }

  handleCheckoutClick = () => {
    const { shoppingbasket, config, onAddNotification } = this.props;
    let events = [];
    shoppingbasket.items.forEach(item => {
      for (let index = 0; index < item.amount; index++) {
        events.push(Events.buildEventObj(item.id, config.purchaseEventType));
      }
    });

    Events.send(events, config.url).then(() => {
      var newBask = basketTool.checkoutBasket(config.name);
      this.props.onBasketChange(newBask);
      onAddNotification('Checkout completed', <span>We will find some better products for you next time around</span>, 'success')
    });
  }

  getBasketRecommendations() {
    const { shoppingbasket } = this.props;
    let entities = shoppingbasket.items.map(i => {
      return {
        type: "Product",
        id: String(i.id)
      }
    });

    if (entities.length > 0) {
      var resultOptionsRequest = {
        take: 8
      }

      loop54Client.getBasketRecommendations(entities, resultOptionsRequest).then(response => {
        this.setState({
          recItems: this.renderRecProducts(response.data.results.items)
        });
      })
    } else {
      this.setState({ recItems: [] });
    }
  }

  renderRecProducts = (items) => {
    const { config, onBasketChange, onAddNotification, diagnosticSettings, onItemSelected, multiSelectedItems } = this.props;

    var result = [];
    var columns = [];

    if (items.length > 0) {
      result.push(
        <Row key={Math.random()}>
          <Col span={24}>
            <h2>Recommended Products</h2>
          </Col>
        </Row>
      )

      items.map(product => {
        columns.push(
          <Col lg={diagnosticSettings.diagnosticModeActive ? 3 : 6} md={24} sm={24} xs={24} className="results-card" key={Math.random()}>
            <ResultItem
              diagnosticSettings={diagnosticSettings}
              item={product}
              config={config}
              onBasketChange={onBasketChange}
              onAddNotification={onAddNotification}
              onItemSelected={onItemSelected}
              multiSelectedItems={multiSelectedItems} />
          </Col>
        )
        return false;
      })

      result.push((
        <Row className="results-row" key={Math.random()}>
          {columns}
        </Row>
      ))
    }

    return result;
  }

  renderBasketItems = () => {
    const { diagnosticSettings } = this.props;

    let items = this.props.shoppingbasket.items.map((i) => {
      let totalPrice = parseInt(i.price.replace(",-", "")) * i.amount;
      return <Col key={i.id} className="checkout-basket-item" span={diagnosticSettings.diagnosticModeActive ? 12 : 24}>
        <Card>
          <div className="product-image">
            <img onClick={() => { this.handleProductClick(i) }} src={i.imageUrl} alt="product" />
          </div>
          <div className="product-info">
            <div onClick={() => { this.handleProductClick(i) }} className="product-title-container">
              <div className="title">{i.title}</div>
              <div className="brand">{i.brand}</div>
            </div>
            <div className="price">
              <span className="col-header">Unit Price</span>
              {i.price}
            </div>
            <div className="amount">
              <span className="col-header">Amount</span>
              <InputNumber min={1} max={100} defaultValue={i.amount} onChange={(val) => this.onAmountChange(val, i)} />
            </div>
            <div className="total-price">
              <span className="col-header">Total</span>
              {totalPrice},-
            </div>
          </div>
          <Button className="delete-product-btn" size="small" icon={<DeleteFilled />} type="white" onClick={() => { basketTool.removeItemFromBasket(this.props.config.name, i, this.props.onBasketChange) }}></Button>
        </Card>
      </Col>
    });
    return items;
  }

  render() {
    const { Content } = Layout;
    const totalBasketItems = sumBy(this.props.shoppingbasket.items, i => i.amount);
    const totalBasketCost = sumBy(this.props.shoppingbasket.items, i => i.amount * parseInt(i.price.replace(",-", "")));
    return (
      <Layout>
        <Header {...this.props} onSearchFieldChange={this.handleSearch} />
        <Content>
          <Row className="checkout-row">
            <Col span={17}>
              <Row>
                <Col span={24} className="basket-header">
                  <div>You have <b>{totalBasketItems}</b> products in your basket</div>
                </Col>
              </Row>
              <Row>
                {this.renderBasketItems()}
              </Row>
            </Col>
            <Col className="checkout-summary-col" span={7}>
              <div className="checkout-summary-sticky-section">
                <Card className="checkout-summary-card">
                  <div className="checkout-summary-total">
                    <span>Total</span>
                    <span>{totalBasketCost},-</span>
                  </div>
                  <div className="checkout-summary-detailed">
                    <span>{totalBasketItems} Products</span>
                    <span>{totalBasketCost},-</span>
                  </div>
                </Card>
                <Button disabled={this.props.shoppingbasket.items.length <= 0} onClick={this.handleCheckoutClick} size="large" type="primary" block={true} icon={<ShoppingCartOutlined />}>Checkout</Button>
              </div>
            </Col>
          </Row>
          {this.state.recItems}
        </Content>
        <Footer></Footer>
      </Layout>
    );
  }
}

Checkout.contextTypes = {
  router: PropTypes.object
}

export default DemoWrapper(Checkout);