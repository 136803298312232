import React, { Component } from 'react';
import {
  Button,
  Tag,
  Row,
  Col,
  Input,
  Drawer,
  Menu,
  Popconfirm,
  Tooltip,
  Switch
} from 'antd';
import {
  SettingOutlined,
  LayoutOutlined,
  BgColorsOutlined,
  DeleteFilled,
  FileImageFilled,
  CloseOutlined,
  CopyOutlined,
  InfoCircleFilled
} from '@ant-design/icons';
import { BlockPicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './settingsModal.css';

import LayoutLMR from '../../images/layout-lmr.svg';
import LayoutLRB from '../../images/layout-lrb.svg';
import LayoutTMB from '../../images/layout-tmb.svg';
import LayoutTLR from '../../images/layout-tlr.svg';
import ColorLightGreen from '../../images/layout-color-lightgreen.svg';
import ColorDarkGreen from '../../images/layout-color-darkgreen.svg';
import ColorGrey from '../../images/layout-color-grey.svg';

const defaultColors = {
  primary: "#000073",
  primaryText: "#ffffff",
  darkGrey: "#DBC5F8",
  darkGreyText: "#000073",
  addToCart: "#DBC5F8",
  addToCartText: "#000073",
  header: "#ffffff",
  headerText: "#42525f",
  headerCartBackground: "#4EFF99",
  headerCart: "#000073",
  headerTitle: "#000073",
  bodyBg: "#f5f8ff",
  text: "#42525f",
  headerImageUrl: null
};

const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];

class SettingsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      active: 'TMB',
      apiKey: null,
      diagnosticSettings: { diagnosticModeActive: false, customCardAttributes: [] },
      overrideEngineUrl: null,
      overrideEngineUrlError: null,
      colorPickerActive: null,
      settingsOverwriteJson: null,
      colors: defaultColors,
      openKeys: ["sub1"],
      localCustomCardAttributes: [],
      localCustomCardAttributesChanged: false,
      localcustomDataJsonChanged: false,
      customDataJson: null,
      savedCustomCardAttributes: [],
    }
  }

  componentWillMount() {
    if (this.props.demoName) {
      let layoutStorage = localStorage.getItem(this.props.demoName + '-layout');
      if (layoutStorage) {
        let newLayout = 'TMB'
        switch (layoutStorage) {
          case '{"filters":"top","related":"right"}':
            newLayout = 'TLR'
            break;
          case '{"filters":"top","related":"bottom"}':
            newLayout = 'TMB'
            break;
          case '{"filters":"left","related":"bottom"}':
            newLayout = 'LRB'
            break;
          case '{"filters":"left","related":"right"}':
            newLayout = 'LMR'
            break;
          default:
            break;
        }

        this.setState({
          active: newLayout
        })
      }

      let diagnosticSettings = this.props.diagnosticSettings;
      if (diagnosticSettings) {
        this.setState({
          diagnosticSettings: diagnosticSettings,
          localCustomCardAttributes: diagnosticSettings.customCardAttributes,
          customDataJson: diagnosticSettings.customJsonData
        })
      }

      let savedCustomCardAttributes = localStorage.getItem('globalSavedCustomCardAttributes');
      if (savedCustomCardAttributes) {
        this.setState({ savedCustomCardAttributes: JSON.parse(savedCustomCardAttributes) });
      }

      let overrideEngineUrl = this.props.overrideEngineUrl;
      if (overrideEngineUrl) {
        this.setState({
          overrideEngineUrl: overrideEngineUrl
        })
      }

      let apiKeyStorage = localStorage.getItem('globalApiKey');
      if (apiKeyStorage) {
        this.setState({
          apiKey: apiKeyStorage
        })
      }

      let colors = this.props.colors;
      if (colors) {
        this.setState({
          colors: colors
        })
      }
    }
  }

  changeLayout = (layout) => {
    if (layout !== this.state.active) {
      this.setState({
        active: layout
      }, this.props.onChangeLayout(layout))
    }
  }

  toggleDiagnosticMode = (checked) => {
    let diagnosticSettings = this.state.diagnosticSettings;
    diagnosticSettings.diagnosticModeActive = checked;
    this.setState({ diagnosticSettings: diagnosticSettings });

    this.closeDrawer();
    this.props.onChangeDiagnosticSettings(this.state.diagnosticSettings, true);
  }

  saveOverrideEngineUrl = () => {
    if (!this.state.overrideEngineUrlError) {
      this.closeDrawer();
      this.props.onChangeOverrideEngineUrl(this.state.overrideEngineUrl, true);
    }
  }

  clearOverrideEngineUrl = () => {
    let overrideEngineUrl = this.state.overrideEngineUrl;
    overrideEngineUrl = null;
    this.setState({ overrideEngineUrl: overrideEngineUrl });
    this.closeDrawer();
    this.props.onChangeOverrideEngineUrl(overrideEngineUrl, true);
  }

  updateOverrideEngineUrl(evt) {
    let overrideEngineUrl = this.state.overrideEngineUrl;
    overrideEngineUrl = evt.target.value;
    this.setState({
      overrideEngineUrl: overrideEngineUrl,
      overrideEngineUrlError: !this.validateUrl(evt.target.value) ? "The url is not valid" : null
    });
  }

  updateApiKey(evt) {
    let apiKey = this.state.apiKey;
    apiKey = evt.target.value;
    this.setState({
      apiKey: apiKey
    });
  }

  storeApiKey(evt) {
    const { apiKey } = this.state;

    if (apiKey === "") {
      localStorage.removeItem('globalApiKey')
    } else {
      localStorage.setItem('globalApiKey', apiKey);
    }

    location.reload();
  }


  validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  showDrawer = () => {
    document.body.classList.add("settings-drawer-active");
    this.setState({ open: true });
  }

  closeDrawer = () => {
    document.body.classList.remove("settings-drawer-active");
    this.setState({ open: false })
  }

  handleCpClick = (prop) => {
    this.setState({ colorPickerActive: prop })
  };

  handleCpClose = (prop) => {
    this.setState({ colorPickerActive: null })
  };

  handleCpChange = (color, propKey) => {
    var colors = { ...this.state.colors }
    colors[propKey] = color;
    this.setState({ colors: colors });
    //The object compare here is to reset the colors localStorage object if all the colors are equal to their default state
    this.props.onChangeDesign(colors, JSON.stringify(colors) === JSON.stringify(defaultColors));
    this.handleCpClose(propKey);
  };

  clearDesignColors = () => {
    this.setState({ colors: defaultColors });
    this.props.onChangeDesign(defaultColors, true);
  };

  handleOverWriteSettingsJson = () => {
    let parsedJson;
    try {
      parsedJson = JSON.parse(this.state.settingsOverwriteJson);
    } catch (e) {
      this.props.onAddNotification("Invalid Json", "The provided json is not valid Json format", "error");
      return;
    }
    this.setState({ colors: parsedJson });
    this.props.onChangeDesign(parsedJson);
  };

  _renderColorPickerDropDown = (colorKey, name) => {
    let color = this.state.colors[colorKey];
    let resetButton = <Tooltip title="Restore this to default value" placement="left">
      <CloseOutlined className="reset-btn" onClick={(e) => { this.handleCpChange(defaultColors[colorKey], colorKey) }} />
    </Tooltip>;

    return <div className="color-picker-dropdown">
      {color !== defaultColors[colorKey] && resetButton}
      <Button block="true" onClick={() => this.handleCpClick(colorKey)}>
        {name}
        <span className="color-picker-icon" style={{ backgroundColor: color }}></span>
      </Button>
      {this.state.colorPickerActive === colorKey && <div className="color-picker-dropdown-popover">
        <div className="color-picker-dropdown-cover" onClick={() => this.handleCpClose(colorKey)} />
        <BlockPicker color={color} onChange={(color) => this.handleCpChange(color.hex, colorKey)} />
      </div>}
    </div>;
  }

  handleImgUrlChange = (data, save) => {
    data = data === "" ? null : data
    var colors = { ...this.state.colors }
    colors.headerImageUrl = data;
    this.setState({ colors: colors });

    if (save) {
      this.props.onChangeDesign(colors, JSON.stringify(colors) === JSON.stringify(defaultColors));
    }
  };

  onOpenChange = keys => {
    const latestOpenKey = keys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys })
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] })
    }
  };

  addCustomCardAttribute = () => {
    let localCustomCardAttributes = this.state.localCustomCardAttributes;
    localCustomCardAttributes.push({ id: Math.random(), name: "", attribute: "" });
    this.setState({
      localCustomCardAttributes: localCustomCardAttributes,
      localCustomCardAttributesChanged: true
    });
  }

  saveCustomCardAttributes = () => {
    let diagnosticSettings = this.state.diagnosticSettings;
    diagnosticSettings.customCardAttributes = this.state.localCustomCardAttributes;
    this.setState({
      diagnosticSettings: diagnosticSettings,
      localCustomCardAttributesChanged: false
    });
    this.props.onChangeDiagnosticSettings(this.state.diagnosticSettings);
  }

  removeCustomCardAttribute = (item) => {
    let localCustomCardAttributes = this.state.localCustomCardAttributes;
    let index = localCustomCardAttributes.indexOf(item);
    if (index !== -1) {
      localCustomCardAttributes.splice(index, 1);
      this.setState({
        localCustomCardAttributes: localCustomCardAttributes,
        localCustomCardAttributesChanged: true
      });
    }
  }

  updateCustomCardAttributeObj(evt, customCardAttributeObj) {
    let localCustomCardAttributes = this.state.localCustomCardAttributes;
    let obj = localCustomCardAttributes.find(cca => cca === customCardAttributeObj);
    if (obj) {
      obj.attribute = evt.target.value;
    }
    this.setState({
      localCustomCardAttributes: localCustomCardAttributes,
      localCustomCardAttributesChanged: true
    });
  }

  saveCustomCardAttributesToStorage = () => {
    const name = prompt("Save the current settings as: ");
    if (name != null) {
      const savedCustomCardAttributes = this.state.savedCustomCardAttributes;
      savedCustomCardAttributes.push({name, attributes: this.state.localCustomCardAttributes.map(a => a.attribute)})
      localStorage.setItem('globalSavedCustomCardAttributes', JSON.stringify(savedCustomCardAttributes));
      this.setState({
        savedCustomCardAttributes
      }, () => this.saveCustomCardAttributes())
    }
  }

  loadCustomCardAttributes = (savedSettings) => {
    this.setState({
      localCustomCardAttributes: savedSettings.attributes.map(attribute => ({ id: Math.random(), name: "", attribute })),
      localCustomCardAttributesChanged: true
    }, () => this.saveCustomCardAttributes());
  }

  _renderCustomCardAttributesInputs() {
    return this.state.localCustomCardAttributes.map(customCardAttribute => {
      return <div key={customCardAttribute.id} className="custom-card-attribute-field">
        <Button size="small" icon={<DeleteFilled />} onClick={() => { this.removeCustomCardAttribute(customCardAttribute) }}></Button>
        <Input key={customCardAttribute.id + "2"} style={{ display: "block" }} addonBefore="Attribute" value={customCardAttribute.attribute} onChange={evt => this.updateCustomCardAttributeObj(evt, customCardAttribute)} />
      </div>
    });
  }

  _renderSavedCustomCardAttributes() {
    return this.state.savedCustomCardAttributes.map(savedSettings => {
      return <Button size="small" onClick={() => this.loadCustomCardAttributes(savedSettings)}>{savedSettings.name}</Button>
    });
  }

  handleSaveCustomDataJson = () => {
    let parsedJson;
    if (this.state.customDataJson && this.state.customDataJson.length > 0) {
      try {
        parsedJson = JSON.parse(this.state.customDataJson);
      } catch (e) {
        this.props.onAddNotification("Invalid Json", "The provided json is not valid Json format", "error");
        return;
      }
    }

    let diagnosticSettings = this.state.diagnosticSettings;
    diagnosticSettings.customDataJson = parsedJson;
    this.setState({
      diagnosticSettings: diagnosticSettings,
      localcustomDataJsonChanged: false
    });

    this.props.onChangeDiagnosticSettings(this.state.diagnosticSettings);
    this.props.onAddNotification("Custom data json saved", "The provided JSON will be applied to all requests", "success");
  };

  render() {
    const { apiKey, open, diagnosticSettings,overrideEngineUrl, overrideEngineUrlError, localCustomCardAttributesChanged, localcustomDataJsonChanged } = this.state;
    const { SubMenu } = Menu;
    const colorsInStorage = localStorage.getItem(this.props.demoName + '-colors');
    const { Search } = Input;
    const { TextArea } = Input;
    return (
      <React.Fragment>
        <Button size="large" type="text" onClick={this.showDrawer}>
          <SettingOutlined /> Demo Settings
        </Button>
        <Drawer
          className="settings-drawer"
          title="Demo settings"
          placement="right"
          closable={true}
          width="360px"
          onClose={this.closeDrawer}
          visible={open}>
          <Menu forceSubMenuRender={true} openKeys={this.state.openKeys} onOpenChange={this.onOpenChange} mode="inline" defaultOpenKeys={['sub1']}>

            <SubMenu icon={<LayoutOutlined />} title="Layout" key="sub1">
              <Menu.Item>
                <Row className="drawer-settings-section">
                  <Col span={24}>
                    <h3>Choose a layout</h3>
                    <div className="layout-select">
                      <img alt="LMR" src={LayoutLMR} className={this.state.active === 'LMR' ? 'active' : null} onClick={() => { this.changeLayout('LMR') }} />
                      <img alt="LRB" src={LayoutLRB} className={this.state.active === 'LRB' ? 'active' : null} onClick={() => { this.changeLayout('LRB') }} />
                      <img alt="TMB" src={LayoutTMB} className={this.state.active === 'TMB' ? 'active' : null} onClick={() => { this.changeLayout('TMB') }} />
                      <img alt="TLR" src={LayoutTLR} className={this.state.active === 'TLR' ? 'active' : null} onClick={() => { this.changeLayout('TLR') }} />
                    </div>
                  </Col>
                  <Col className="layout-info" span={24}>
                    <Tag size="tiny">
                      <img src={ColorGrey} alt="color explaination" />Filters/Facets</Tag>
                    <Tag size="tiny"><img src={ColorLightGreen} alt="color explaination" />Direct Results</Tag>
                    <Tag size="tiny"><img src={ColorDarkGreen} alt="color explaination" />Related Results</Tag>
                  </Col>
                </Row>
              </Menu.Item>
            </SubMenu>

            <SubMenu icon={<BgColorsOutlined />} title="Theme" key="sub2">
              <Menu.Item>
                <Row className="drawer-settings-section">
                  <Col span={24}>
                    <h3>Logo</h3>
                    <div style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}>
                      <Search value={this.state.colors.headerImageUrl} onChange={evt => this.handleImgUrlChange(evt.target.value)} enterButton="Add" addonBefore={<FileImageFilled />} onSearch={evt => this.handleImgUrlChange(evt, true)} placeholder="Enter image url" />
                    </div>
                    <h3>Main colors</h3>
                    {this._renderColorPickerDropDown("primary", "Primary color")}
                    {this._renderColorPickerDropDown("primaryText", "Primary text color")}
                    {this._renderColorPickerDropDown("darkGrey", "Secondary color")}
                    {this._renderColorPickerDropDown("darkGreyText", "Secondary text color")}
                    <h3>Add to cart button colors</h3>
                    {this._renderColorPickerDropDown("addToCart", "Add to cart bg-color")}
                    {this._renderColorPickerDropDown("addToCartText", "Add to cart text color")}
                    <h3>Header colors</h3>
                    {this._renderColorPickerDropDown("header", "Header bg-color")}
                    {this._renderColorPickerDropDown("headerText", "Header text color")}
                    {this._renderColorPickerDropDown("headerCartBackground", "Header cart button bg-color")}
                    {this._renderColorPickerDropDown("headerCart", "Header cart button text color")}
                    {this._renderColorPickerDropDown("headerTitle", "Header title color")}
                    <h3>Page colors</h3>
                    {this._renderColorPickerDropDown("text", "Page text color")}
                    {this._renderColorPickerDropDown("bodyBg", "Page bg-color")}
                    {colorsInStorage &&
                      <React.Fragment>
                        <Button.Group>
                          <Popconfirm placement="topRight" title="Are you sure you want to reset all the values to their default state?" okButtonProps={{ type: "default" }} onConfirm={this.clearDesignColors} okText="Yes" cancelText="No">
                            <Button size="large" icon={<DeleteFilled />}>Reset theme</Button>
                          </Popconfirm>
                          <CopyToClipboard text={JSON.stringify(this.state.colors)} onCopy={() => this.props.onAddNotification("Copied", "Your settings were copied to your clipboard", 'info')}>
                            <Button size="large" icon={<CopyOutlined />}>Copy theme</Button>
                          </CopyToClipboard>
                        </Button.Group>
                      </React.Fragment>
                    }
                    <div style={{ marginTop: "1rem" }}>
                      <Popconfirm
                        icon={null}
                        placement="topRight"
                        title={<TextArea style={{ width: 300 }} rows={4} onChange={(evt) => this.setState({ settingsOverwriteJson: evt.target.value })}></TextArea>}
                        onConfirm={this.handleOverWriteSettingsJson}
                        okText="Add"
                        okButtonProps={{ type: "default" }}
                        cancelText="Cancel">
                        <Button block="true" size="large">Add saved theme</Button>
                      </Popconfirm>
                    </div>
                  </Col>
                </Row>
              </Menu.Item>
            </SubMenu>

            <SubMenu icon={<SettingOutlined />} title="Advanced Settings" key="sub3">
              <Menu.Item>
                <Row className="drawer-settings-section">
                  <Col span={24}>

                    <div className="setting-section">
                      <h3>API key</h3>
                      <Search value={apiKey} onChange={evt => this.updateApiKey(evt)} enterButton="Store" onSearch={() => this.storeApiKey()} placeholder="Enter API key" />
                    </div>

                    <h3>Diagnostic mode</h3>
                    <div className="feature-switch" key={Math.random()}>
                      <span className="feature-switch-label" >{diagnosticSettings.diagnosticModeActive ? 'Inactivate' : 'Activate'} diagnostic mode</span>
                      <Switch checked={diagnosticSettings.diagnosticModeActive} onChange={this.toggleDiagnosticMode} />
                    </div>

                    {diagnosticSettings.diagnosticModeActive &&
                      <React.Fragment>
                        <div className="advanced-setting-section">
                          <h3>Override engine url</h3>
                          <Input placeholder="Enter url" value={overrideEngineUrl} onChange={evt => this.updateOverrideEngineUrl(evt)} />
                          {overrideEngineUrlError && <span className="input-error">{overrideEngineUrlError}</span>}
                          <div className="btn-container">
                            <Button disabled={overrideEngineUrlError || !overrideEngineUrl} onClick={this.saveOverrideEngineUrl}>Save</Button>
                            {overrideEngineUrl && <Button onClick={this.clearOverrideEngineUrl}>Clear</Button>}
                          </div>
                        </div>

                        <div className="advanced-setting-section">
                          <h3>
                            Custom card attributes
                            {' '}
                            <Tooltip
                                overlayClassName="advanced-setting-custom-attributes-tooltip"
                                title={<>
                                  <div>
                                    To show attributes on product cards, add the name of the attribute like <span>'&lt;attributeName&gt;'</span>.
                                  </div>
                                  <div>
                                    To show diagnostic data, add it like <span>'d.&lt;attributeName&gt;'</span>.
                                  </div>
                                  <div>
                                    To change the precision of numeric diagnostic data, add it like <span>'d.&lt;attributeName&gt;[&lt;number&gt;]'</span>, where '&lt;number&gt;' is the number of decimal places.
                                  </div>
                                </>}>
                              <InfoCircleFilled />
                            </Tooltip>
                          </h3>
                          {this._renderCustomCardAttributesInputs()}
                          <Button style={{ marginTop: "0.5rem", display: "block" }} block="true" onClick={() => { this.addCustomCardAttribute() }}>Add new card attribute</Button>
                          {localCustomCardAttributesChanged && <Button style={{ marginTop: "0.5rem", display: "block" }} block="true" type="primary" onClick={() => { this.saveCustomCardAttributes() }}>Save changes</Button>}
                          {localCustomCardAttributesChanged && <Tooltip placement="top" title="Save the card attributes globally so you can access them from any demo"><Button style={{ marginTop: "0.5rem", display: "block" }} block="true" type="primary" onClick={() => { this.saveCustomCardAttributesToStorage() }}>Save and store changes</Button></Tooltip>}
                          {this._renderSavedCustomCardAttributes()}
                        </div>

                        <div className="advanced-setting-section">
                          <h3>Custom data <Tooltip title="Add JSON data here to be applied in the 'Custom data' field for each request"><InfoCircleFilled></InfoCircleFilled></Tooltip></h3>
                          <TextArea defaultValue={JSON.stringify(diagnosticSettings.customDataJson)} rows={3} onChange={(evt) => this.setState({ customDataJson: evt.target.value, localcustomDataJsonChanged: true })}></TextArea>
                          {localcustomDataJsonChanged && <Button style={{ marginTop: "0.5rem", display: "block" }} block="true" type="primary" onClick={() => { this.handleSaveCustomDataJson() }}>Save changes</Button>}
                        </div>
                      </React.Fragment>
                    }
                  </Col>
                </Row>
              </Menu.Item>
            </SubMenu>

          </Menu>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default SettingsModal;
