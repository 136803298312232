import hash from 'string-hash';
import loop54Client from '../utils/loop54Client';

let getSelectedFacets = (response, facets) => {
  facets.map((facet) => {
    return response.include(facet)
  })
}

let flattenObject = (ob) => {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) === 'object') {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

let searchObject = (key, ob) => {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) === 'object') {
      let flatObject = searchObject(key, ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        if (x === key) {
          toReturn[x] = flatObject[x];
        }
      }
    }
    if(i === key) {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

let categoriesFormat = {
  hashToPath(hash, categoryName, categories) {
    if(categories.length > 0) {
      let category = categories.find(cat => {return cat.titleInDemo.toLowerCase() === categoryName});
      let result;

      if(category) {
        let flatCategories = flattenObject(category.items);

        for(let key in flatCategories) {
          if(flatCategories[key] === parseInt(hash, 0)) {
            result = flatCategories[key.replace('hash', 'path')]
          }
        }
      }
      return result;
    }
  },

  nameToObject(name, categories) {
    let flatCategories = searchObject(name, categories);

    return flatCategories;
  },

  pathToObject(path, delimiter) {
    if(!delimiter) { delimiter = '>' }
    return path.split(delimiter)
  },

  getCategoryDelimeter(categoryTitleInDemo, categories) {
    return categories.find(cat => cat.titleInDemo.toLowerCase() === categoryTitleInDemo.toLowerCase()).delimiterInValue;
  },

  async getCategoriesState(config) {
    if(config.categoryListingSettings.length > 0) {
      return await this.fetchUsingConfig(config.categoryListingSettings);
    } else {
      return await this.fetchUsingEngine();
    }
  },

  async fetchUsingEngine() {
    const response = await loop54Client.getIndexedAttributes();
    const indexedAttributes = response.data.indexedAttributes;

    const promises = indexedAttributes.map(async (attribute) => {
      const categoryValues = await this.getCategories(attribute, null)
      return {
        titleInDemo: attribute,
        attributeOnProduct: attribute,
        delimiterInValue: null,
        items: categoryValues, 
      };
    });

    const categories = await Promise.all(promises);
    return categories;
  },

  async fetchUsingConfig(categoryListingSettings) {
    const promises = categoryListingSettings.map(async (setting) => {
      const categoryValues = await this.getCategories(setting.attributeOnProduct, setting.delimiterInValue);
      return {items: categoryValues, ...setting};
    });

    const categories = await Promise.all(promises);
    return categories;
  },

  async getCategories(entityType, delimiter){
  
    //try to fetch using the new API, if it fails, use the old one (engine version 5.0 and earlier)
    let response = loop54Client.getIndexedAttributeValues(entityType);
    return response.then(results => {
      let items = results.data.values.map(s => { return {id:s} });
      return this.toObject(items, delimiter);
    })
	.catch(err => {
      console.log("getIndexedAttributeValues failed, falling back to getEntities");

      return this.paginateCategoryEntity(entityType)
        .then(paginatedCategoryResults => {
          let items = [].concat.apply([], paginatedCategoryResults.map(i => i.data.results.items));
          return this.toObject(items, delimiter);
        });
      }
    );
  },
  
  async paginateCategoryEntity(entityType){
    
    let take = 1000;
    let request = {
      take:take,
      skip: 0,
      filter:{
        type:"type",
        value: entityType
      }
    };

    let promises = [];
    let currentPage = 0;
    let totalPages = null;
    
    while (totalPages === null || currentPage < totalPages) {
      request.skip = currentPage * take;
      let response = loop54Client.getEntities(request);
      if (totalPages === null) {
        let results = await response;
        totalPages = Math.ceil(results.data.results.count / take);
      }
      promises.push(response);
      currentPage++;
    }
    return Promise.all(promises);
  },

  toObject(categories, delimiter) {
    if(!delimiter) { delimiter = '>' }
    if(categories) {
      let result = {};
      categories.map(category => {
        var path = category.id;
        var pathObject = path.split(delimiter);
        for(var i = 0; i < pathObject.length; i++) {
          if(!result[pathObject[0]]) {result[pathObject[0]] = {path: pathObject[0], hash: hash(pathObject[0]), level2: {}}}
          if(i === 1) {result[pathObject[0]].level2[pathObject[1]] = {path: path, hash: hash(path)}}
        }

        return true;
      })
      return result;
    }
  },

  request(item, selectedFacets, {config, category, page, sortAttribute, sortDescending}) {
    var req = {
      attribute:{name: category.attributeOnProduct, value: item.value},
      resultOptions:{
        take: config.continousScrolling ? config.directResultsPageSize * (page + 1) : config.directResultsPageSize,
        skip: config.continousScrolling ? 0 : config.directResultsPageSize * page,
        sortBy:[],
        facets:[]
      }
    }
    
    if(sortAttribute) {
      const sortBy = {
        type: sortAttribute === "relevance" ? "relevance" : "attribute",
        order: sortDescending ? "desc" : "asc"
      };
      if (sortAttribute !== "relevance") {
        sortBy.attributeName = sortAttribute;
      }
      req.resultOptions.sortBy.push(sortBy);
    }

    if(config.filters){
      config.filters.forEach(filter => {
        if (filter.type === "numberRange"){
          let attribute = filter.responseParameter[0].replace(".MinValue","").replace(".MaxValue","");
          req.resultOptions.facets.push(
            {
              name:filter.name,
              attributeName:attribute,
              type:"range",
              selected:selectedFacets[filter.name] ? {
                min:selectedFacets[filter.name].min,
                max:selectedFacets[filter.name].max
              } : null
            }
          );
        }else {
          req.resultOptions.facets.push(
            {
              name:filter.name,
              attributeName:filter.responseParameter,
              type:"distinct",
              selected: selectedFacets && selectedFacets[filter.requestParameter] && selectedFacets[filter.requestParameter].length > 0 ? selectedFacets[filter.requestParameter] : []
            }
          );
        }
      })
    }

    return req;
  },

  response(response) {
    if(!response.status === 200) { return {error: "Something went wrong, try again"} }

    return response.data;
  },

  checkAvailableFacets(selectedFacets, response) {
    for(var key in selectedFacets) {
      if(response[key]) {
        selectedFacets = getSelectedFacets(response[key], selectedFacets[key]);
      } else {
        delete selectedFacets[key];
      }
    }
    return selectedFacets;
  }
}

export default categoriesFormat;
