import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import 'antd/dist/antd.min.css';
import './index.css';
import './fonts/inter/inter.css';

import Search from './pages/SearchContainer';
import Category from './pages/CategoryContainer';
import Checkout from './pages/Checkout';
import Index from './pages/IndexContainer';
import Product from './pages/ProductContainer';
import Loop54Splash from './Loop54Splash';
import registerServiceWorker from './registerServiceWorker';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';

ReactDOM.render(

  <Router>
    <div>
      <ErrorBoundary>
        <Switch>
          <Route exact path='/' component={Loop54Splash} />
          <Route exact path='/:demoName' component={Index} />
          <Route path='/:demoName/search' component={Search} />
          <Route path='/:demoName/checkout' component={Checkout} />
          <Route path='/:demoName/product/:externalId' component={Product} />
          <Route path='/:demoName/:categoryName/:categoryHash' component={Category} />
        </Switch>
      </ErrorBoundary>
    </div>
  </Router>, document.getElementById('root')

);

registerServiceWorker();
