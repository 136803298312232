import React, { Component } from 'react';
import { List, Button, Checkbox, Slider, Card } from 'antd';

class FacetItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      value: [Math.floor(this.props.sortedFacet.currentMinValue), Math.ceil(this.props.sortedFacet.currentMaxValue)],
    }
  }

  handleShowMore = (action) => {
    if (action === "open") {
      this.setState({ open: true })
    } else {
      this.setState({ open: false })
    }
  }

  handleRangeChange = (value) => {
    const { filter } = this.props;
    var res = {}
    res[filter.name] = { min: parseInt(value[0], 0), max: parseInt(value[1], 0) };
    this.props.onRangeFacet(res);
  }

  renderList = () => {
    const { sortedFacet, requestParameter, onSelectedFacets } = this.props;
    var facet = sortedFacet;

    if (!this.state.open) {
      facet = sortedFacet.slice(0, 5);
    }

    let listItems = facet.map(item => {
      if (typeof (item.item) !== 'string') { return null }
      return (
        <List.Item key={Math.random()}>
          <Checkbox checked={item.selected} value={item.item} name={requestParameter} onChange={onSelectedFacets}>{item.item}</Checkbox>
          <span>({item.count})</span>
        </List.Item>
      )
    })

    listItems = listItems.filter(n => n)
    if (listItems.length === 0) { return null }

    return (
      <Card className="facet-card" title={this.props.filter.name}>
        <List>
          {listItems}
          {sortedFacet.length > 5 && !this.state.open ? <Button block={true} className="show-more-button" onClick={() => { this.handleShowMore("open") }}>Show more</Button> : null}
          {sortedFacet.length > 5 && this.state.open ? <Button block={true} className="show-more-button" onClick={() => { this.handleShowMore("close") }}>Show less</Button> : null}
        </List>
      </Card>
    )
  }

  renderRange = () => {
    if (isNaN(this.props.sortedFacet.minValue) && isNaN(this.props.sortedFacet.maxValue)) { return null; }

    const minValue = Math.floor(this.props.sortedFacet.minValue);
    const maxValue = Math.ceil(this.props.sortedFacet.maxValue);
    const marks = { [minValue]: `${minValue}:-`, [maxValue]: `${maxValue}:-` }
    return (
      <Card className="facet-card" title={this.props.filter.name}>
        <List>
          <Slider
            className="price-slider"
            range
            marks={marks}
            defaultValue={this.state.value}
            min={minValue}
            max={maxValue > minValue ? maxValue : maxValue + 1}
            onAfterChange={this.handleRangeChange}
            onChange={value => { this.setState({ value: value }) }}
          />
        </List>
      </Card>
    )
  }

  render() {
    const { filter } = this.props;

    return (
      <React.Fragment>
        {filter.type === 'string' ? this.renderList() : null}
        {filter.type === 'numberRange' ? this.renderRange() : null}
      </React.Fragment>
    );
  }
}

export default FacetItem;
