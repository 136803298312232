import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DemoWrapper from '../DemoWrapper';
import Index from './Index';

class IndexContainer extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      searchTerm: '',
      selectedFacets: [],
      results: null
    }
  }

  handleSearch = (item, facets) => {
    var redirect = '/' + this.props.demoName + '/search?q=' + encodeURIComponent(item.value);
    if(item.facet) {redirect = redirect + '&f=' + encodeURIComponent(item.facet)}
    this.context.router.history.push(redirect)
  }

  handleClearSearch = () => {
    this.setState({ searchTerm: '', results: null, page: 0, selectedFacets: {} })
  }

  handleSelectedFacetsChange = (selectedFacets) => {
    this.handleSearch({value: this.state.searchTerm}, selectedFacets)
  }

  handleChangeLayout = (layout) => {
    let newLayout = {};

    switch(layout) {
      case 'TLR':
        newLayout = {filters: 'top', related: 'right'}
        break;
      case 'TMB':
        newLayout = {filters: 'top', related: 'bottom'}
        break;
      case 'LRB':
        newLayout = {filters: 'left', related: 'bottom'}
        break;
      case 'LMR':
        newLayout = {filters: 'left', related: 'right'}
        break;
      default:
        break;
    }

    this.setState({
      layout: newLayout
    }, localStorage.setItem(this.props.config.name + '-layout', JSON.stringify(newLayout)))
  }

  render() {
    return (
      <Index
        {...this.state}
        {...this.props}
        onSearchFieldChange={this.handleSearch}
        clearSearch={this.handleClearSearch}
        onSelectedFacetsChange={this.handleSelectedFacetsChange}
        onChangeLayout={this.handleChangeLayout}
      />
    );
  }
}

IndexContainer.contextTypes = {
  router: PropTypes.object
}

export default DemoWrapper(IndexContainer);
