import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Layout, Button, Divider } from 'antd';
import { FilterFilled, BugFilled } from '@ant-design/icons';
import classnames from 'classnames';

import './Search.css';

import Header from '../components/header/Header';
import Message from '../components/message';
import SearchResults from '../components/results/SearchResults';
import Facets from '../components/facets/Facets';
import Pagination from '../components/pagination';
import Sort from '../components/sort';
import Footer from '../components/footer';
import DiagnosticQueryInfoPanel from '../components/diagnostic/diagnostic-query-info-panel/DiagnosticQueryInfoPanel';

class Search extends Component {
  constructor(props) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    super(props);
    this.state = {
      showFilters: props.layout.filters === 'left' && vw > 992 ? true : false,
      showDiagnosticQuery: false
    }
  }

  componentDidUpdate(prevProps) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (prevProps.layout !== this.props.layout && this.props.layout.filters === 'left' && vw > 992) {
      this.setState({ showFilters: true })
    }
  }

  toggleShowFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });

    //This scroll to top is for UX purpose.
    //When filters are on top of page, hiding/showing the filters will dissrupt the current scroll pos.
    window.scrollTo(0, 0);
  }

  toggleShowDiagnosticQuery = () => {
    this.setState({ showDiagnosticQuery: !this.state.showDiagnosticQuery });
  }

  generateColorsForContexts = () => {
    let colors = [
      '#bf4e37', // Red
      '#4abf49', // Green
      '#ffe250', // Yellow
      '#4d5ebf', // Blue
      '#f57d3f', // Orange
      '#9b38b9', // Purple
      '#3bdaf5', // Cyan
      '#f054e0', // Pink
      '#bfee4b', // Lime
      '#fdc3e2', // Light Pink
      '#448899', // Teal
      '#cda1ff', // Lavender
      '#9e6f4f', // Brown
      '#fffdd1', // Cream
      '#800000', // Maroon
      '#b4ffc9', // Pale Green
      '#7f8000', // Olive
      '#ffdf9f', // Peach
      '#000073', // Navy
      '#b1b1b1'  // Gray
    ];
    if (this.props.results && this.props.results.customData && this.props.results.customData["Diagnostics.Query"]) {
      return this.props.results.customData["Diagnostics.Query"].contextDistribution.map((cd, index) => {
        return { ...cd, color: colors[index] ? colors[index] : "#ffffff", rank: index + 1 }
      });
    }
  }

  render() {
    const { Content } = Layout;
    const { results, directResults, relatedResults, config, layout, searchTerm, diagnosticSettings, onBasketChange, onAddNotification, onItemSelected, multiSelectedItems } = this.props;
    let resultsWidth = 24;
    let directResultsWidth = 24;
    let directResultsColumns = 4;
    let relatedResultsColumns = 4;
    let relatedResultsWidth = 24;
    let layoutClassName = 'TMB';

    let contextColors = this.generateColorsForContexts()

    // LRB (left right bottom)
    if (layout.filters === 'left' && layout.related !== 'right') { resultsWidth = 18; directResultsColumns = 3; relatedResultsColumns = 3; layoutClassName = 'LRB' }
    // TLR (top left right)
    if (layout.filters !== 'left' && layout.related === 'right') { directResultsWidth = 18; directResultsColumns = 3; relatedResultsWidth = 6; relatedResultsColumns = 1; layoutClassName = 'TLR' }
    // LMR (left middle right)
    if (layout.filters === 'left' && layout.related === 'right') { resultsWidth = 18; directResultsWidth = 17; directResultsColumns = 2; relatedResultsWidth = 7; relatedResultsColumns = 1; layoutClassName = 'LMR' }
    // if no related results are present
    if (!relatedResults || (relatedResults && relatedResults.length === 0)) { directResultsWidth = 24; directResultsColumns = layout.filters === 'left' ? 3 : 4; relatedResultsWidth = 0; relatedResultsColumns = 0; }

    if (diagnosticSettings.diagnosticModeActive) {
      directResultsWidth = 24;
      directResultsColumns = 8;
      relatedResultsWidth = 24;
      relatedResultsColumns = 8;
      layoutClassName = 'TMB'
    }

    return (
      <Layout className={layoutClassName}>
        <Header {...this.props} onRelatedClick={this.props.search} />
        <Content>
          <Row className="search-row">
            <Facets {...this.props} showFilters={this.state.showFilters} />
            <Col lg={resultsWidth} md={24} sm={24} xs={24}>
              <Row>
                <Col className={classnames('search-direct-results', !relatedResults || relatedResults.length <= 0 ? 'no-related' : null)} lg={directResultsWidth} md={24} sm={24} xs={24}>
                  <Row>
                    <Col className="search-top-col" span={24}>
                      {results ? <h2>Found {results.results.count} products for <span className="search-trem">{searchTerm}</span></h2> : null}
                      {results && results.customData && results.customData["Diagnostics.Query"] && diagnosticSettings.diagnosticModeActive && <Button icon={<BugFilled />} style={{ marginRight: "0.5rem" }} onClick={() => this.toggleShowDiagnosticQuery()}>Toggle diagnostic query</Button>}
                      {results && <Button icon={<FilterFilled />} onClick={() => this.toggleShowFilters()} className="show-filters-button">{this.state.showFilters ? 'Hide filters' : 'Show filters'}</Button>}
                      <Sort config={config} resultsCount={directResults ? directResults.length : 0} onSortChange={this.props.onSortChange} sortAttribute={this.props.sortAttribute} sortDescending={this.props.sortDescending} />
                    </Col>
                  </Row>
                  {results ? Message(searchTerm, results.makesSense, results.results.count, results.spellingSuggestions, this.context.router) : null}
                  {diagnosticSettings.diagnosticModeActive && this.state.showDiagnosticQuery && <DiagnosticQueryInfoPanel contextColors={contextColors} diagnosticQuery={results && results.customData["Diagnostics.Query"]}></DiagnosticQueryInfoPanel>}
                  <SearchResults
                    results={directResults ? directResults : []}
                    diagnosticSettings={diagnosticSettings}
                    columns={directResultsColumns}
                    config={config}
                    onBasketChange={onBasketChange}
                    onAddNotification={onAddNotification}
                    relatedResults={relatedResults}
                    onItemSelected={onItemSelected}
                    contextColors={contextColors}
                    diagnosticQuery={results && results["Diagnostics.Query"]}
                    multiSelectedItems={multiSelectedItems}
                  />
                  <Pagination onPageChange={this.props.onPageChange} fetchingResults={this.props.fetchingResults} onShowMore={this.props.onShowMore} continousScrolling={this.props.config.continousScrolling} resultsCount={directResults ? directResults.length : 0} resultsMaxCount={results ? results.results.count : 0} maxPage={results ? Math.ceil(results.results.count / this.props.config.directResultsPageSize) : 0} />
                </Col>
                <Col id="related-results" className="search-related-results" lg={relatedResultsWidth} md={24} sm={24} xs={24}>
                  {(layoutClassName !== "TLR" && layoutClassName !== "LMR") && <Divider></Divider>}
                  {relatedResults && relatedResults.length > 0 ? <h2>Related results</h2> : null}
                  <SearchResults
                    results={relatedResults && relatedResults.length > 0 ? relatedResults : []}
                    diagnosticSettings={diagnosticSettings}
                    columns={relatedResultsColumns}
                    config={config}
                    onBasketChange={onBasketChange}
                    onAddNotification={onAddNotification}
                    onItemSelected={onItemSelected}
                    multiSelectedItems={multiSelectedItems}
                    contextColors={contextColors}
                    diagnosticQuery={results && results["Diagnostics.Query"]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
        <Footer></Footer>
      </Layout>
    );
  }
}

Search.contextTypes = {
  router: PropTypes.object
}

export default Search;
