import React, { Component } from 'react';
import { Table, Drawer } from 'antd';
import { BugOutlined } from '@ant-design/icons';

import { initializeProduct } from '../../../utils/productInitialize';
import './DiagnosticCompareDrawer.css';

class DiagnosticCompareDrawer extends Component {

  _renderTable = () => {
    const { multiSelectedItems, config } = this.props;
    if (!multiSelectedItems || multiSelectedItems.length <= 0) {
      return null;
    }

    let formatedItems = multiSelectedItems.map(i => {
      let formatedItem = initializeProduct(i, config);

      var attributesObject = {};
      i.attributes.forEach(a => {
        attributesObject[a.name] = a.values
      })
      formatedItem.attributes = attributesObject;
      
      return formatedItem;
    });

    let allKeys = [];
    if (formatedItems.some(i => i.diagnostics)) {
      for (const key in formatedItems.find(i => i.diagnostics).diagnostics) {
        allKeys.push({ diagnosticDataKey: true, key: key });
      }
    }

    for (const key in formatedItems[0].attributes) {
      if (key !== "Loop54Diagnostics") {
        allKeys.push({ diagnosticDataKey: false, key: key });
      }
    }

    let dataRows = [];
    allKeys.forEach(key => {
      let obj = { propName: key.diagnosticDataKey ? <React.Fragment>{<BugOutlined />} {key.key}</React.Fragment> : key.key, isSame: false, key: key.key };

      var values = [];
      formatedItems.forEach((item, index) => {
        let value = String(key.diagnosticDataKey ? item.diagnostics[key.key] : item.attributes[key.key])
        obj['product' + index] = value;
        values.push(value);
      });

      if (values.every((val, i, arr) => val === arr[0])) {
        obj.isSame = true;
      }

      dataRows.push(obj);
    });

    const idRow = { propName: "Id", key: "Id" };
    formatedItems.forEach((item, index) => {
      idRow['product' + index] = item.id;
    });
    dataRows.unshift(idRow);

    const imageRow = { propName: "Image", key: "Image" };
    formatedItems.forEach((item, index) => {
      imageRow['product' + index] = <img alt="Product" src={item.imageUrl} style={{ width: "100%", height: "auto", maxWidth: 200, margin: "0 auto", display: "block" }}></img>;
    });
    dataRows.unshift(imageRow);

    const { Column } = Table;

    let columns = formatedItems.map((item, index) => {
      return <Column
        width={350}
        title={item.title}
        dataIndex={`product${index}`}
        key={item.id} />
    })

    return (
      <Table
        rowClassName={(record) => record.isSame && 'table-row-issame'}
        bordered={true}
        pagination={false}
        scroll={{ x: 1300, y: "calc(100vh - 133px)" }}
        dataSource={dataRows}>
        <Column fixed="left" width={300} title="#" dataIndex="propName" key="#" />
        {columns}
      </Table>
    )
  }

  closeDrawer() {
    this.props.onCloseDiagnosticItemCompareDrawer();
  }

  render() {
    return <React.Fragment>
      <Drawer
        className="diagnostic-compare-drawer"
        title="Item compare"
        placement="right"
        closable={true}
        width="100vw"
        destroyOnClose={true}
        onClose={() => this.closeDrawer()}
        visible={this.props.diagnosticItemCompareDrawerOpen}
      >
        {this._renderTable()}
      </Drawer>
    </React.Fragment>
  }
}

export default DiagnosticCompareDrawer;
