import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchField from '../searchField';
import Shoppingbasket from '../shoppingbasket/index';
import PurchaseHistory from '../shoppingbasket/purchaseHistory';
import ResetButton from '../reset/button';
import SettingsModal from '../modals/settingsModal';
import RelatedQueries from '../../components/relatedQueries';
import MenuIndex from '../menu';
import './Header.css';
import classnames from 'classnames';

class Header extends Component {
  render() {
    let noCategories = this.props.categories === undefined || this.props.categories.length <= 0;
    const displayName = this.props.config ? (this.props.config.displayName ? this.props.config.displayName : this.props.config.name) : "Demo";
    return (
      <React.Fragment>
        <div id="demo-header" className={classnames('header-segment', noCategories ? 'header-segment-no-categories' : null)}>
          <div className='header-top-container'>
            <div className="header-title-container">
              {this.props.colors && this.props.colors.headerImageUrl ? <Link className="custom-header-image" to={'/' + this.props.config.name}><span style={{ backgroundImage: `url(${this.props.colors.headerImageUrl})` }}></span></Link> :
                <React.Fragment>
                  <h1 className="header-text">
                    <Link to={'/' + this.props.config.name}>{displayName}</Link>
                  </h1>
                  <span className="demo-made-by">powered by <b>Infinity</b></span>
                </React.Fragment>
              }
            </div>
            <div className="header-searchfield"><SearchField config={this.props.config} onSearchFieldChange={this.props.onSearchFieldChange} results={this.props.results ? this.props.directResults : []} selectedFacets={this.props.selectedFacets} searchTerm={this.props.searchTerm} /></div>
            <div className="header-nav">
              <ResetButton onReset={this.props.onReset} onAddNotification={this.props.onAddNotification} />
              <SettingsModal {...this.props} />
              <PurchaseHistory basket={this.props.shoppingbasket} {...this.props} />
              <Shoppingbasket basket={this.props.shoppingbasket} {...this.props} />
            </div>
          </div>
          <div className='header-bottom-container'>
            <MenuIndex demoName={this.props.config.name} config={this.props.config} entities={this.props.categories} />
          </div>
        </div>
        <div className="header-search-container">
          <div className="wrapper">
            <SearchField config={this.props.config} onSearchFieldChange={this.props.onSearchFieldChange} results={this.props.results ? this.props.directResults : []} selectedFacets={this.props.selectedFacets} searchTerm={this.props.searchTerm} />
            {this.props.results && this.props.results.relatedQueries ? <RelatedQueries relatedQueries={this.props.results.relatedQueries} onRelatedClick={this.props.search} /> : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default React.memo(Header);