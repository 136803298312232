let attribute = {
  imageUrl(entity, productImageUrl, productImageUrlAttributes) {
    var ret = productImageUrl;

    productImageUrlAttributes.forEach((attr, i) => {
      var attrValue = '';

      if (attr === 'ExternalId') {
        attrValue = entity.id;
      } else if (entity.attributes.find(a => a.name === attr)) {
        attrValue = entity.attributes.find(a => a.name === attr).values[0];
      }

      // Replace doesn't like "$&" in the image url...
      ret = ret.split('$' + (i + 1)).join(attrValue);
    });
    return ret;
  }
}

export default attribute;
