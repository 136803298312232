import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';

import handleDropdownOpenState from '../../utils/dropdownTool'
import urlTool from '../../utils/urlTool'

class MenuNonStandardCategoryDropdown extends Component {
  getMenuItems() {
    const { category, demoName, subItemsMaxLength } = this.props;
    return <div>
      <Menu>
        {category.subCategories.map(cat => {
          return <li className={cat.subCategories.length <= 0 && 'no-subcategories'} key={cat.hash}>
            <a className="menu-multi-level-dropdown-heading" href={urlTool.buildCategoryUrl(demoName, category.titleInDemo, cat.hash)}>{cat.name}</a>
            <div className="menu-multi-level-dropdown-subitems">
              {cat.subCategories && cat.subCategories.slice(0, subItemsMaxLength).map(subCat => {
                return <a key={subCat.hash} href={urlTool.buildCategoryUrl(demoName, category.titleInDemo, subCat.hash)}>{subCat.name}</a>
              })}

              {cat.subCategories && cat.hash && cat.subCategories.length > subItemsMaxLength &&
                <a className="category-dropdown-view-all" href={`/${demoName}/${category.titleInDemo.toLowerCase()}/${cat.hash}`}>View all categories for {cat.name}</a>}
            </div>
          </li>
        })}
      </Menu>
    </div>
  }

  handleOnShowState = (open) => {
    handleDropdownOpenState(open);
  }

  render() {
    const { category, dropDownMenuContainerRef } = this.props;
    return (
      <Dropdown overlayClassName="menu-multi-level-dropdown" trigger="click" onVisibleChange={this.handleOnShowState} getPopupContainer={() => dropDownMenuContainerRef} key={Math.random()} overlay={this.getMenuItems()}>
        <Button size="large" type="text">Shop By {category.titleInDemo}</Button>
      </Dropdown>
    );
  }
}

export default MenuNonStandardCategoryDropdown;
