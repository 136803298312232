import React, { Component } from 'react';

import PaginationNumbers from './numbers';
import PaginationShowMore from './showMore';

import './index.css';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      maxPage: null,
      resultsCount: 0,
      resultsMaxCount: null,
    }
  }

  componentWillMount() {
    if(this.props.currentPage !== this.state.page) {
      this.setState({
        currentPage: this.props.currentPage
      })
    }

    if(this.props.maxPage !== this.state.maxPage) {
      this.setState({
        maxPage: this.props.maxPage
      })
    }

    if(this.props.resultsCount !== this.state.resultsCount) {
      this.setState({
        resultsCount: this.props.resultsCount
      })
    }

    if(this.props.resultsMaxCount !== this.state.resultsMaxCount) {
      this.setState({
        resultsMaxCount: this.props.resultsMaxCount
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.currentPage !== this.state.page) {
      this.setState({
        currentPage: nextProps.currentPage
      })
    }

    if(nextProps.maxPage !== this.state.maxPage) {
      this.setState({
        maxPage: nextProps.maxPage
      })
    }

    if(nextProps.resultsCount !== this.state.resultsCount) {
      this.setState({
        resultsCount: nextProps.resultsCount
      })
    }

    if(nextProps.resultsMaxCount !== this.state.resultsMaxCount) {
      this.setState({
        resultsMaxCount: nextProps.resultsMaxCount
      })
    }
  }

  onPageChange = (e) => {
    e.preventDefault();
    let target = e.target.tagName === 'SPAN' ? e.target.parentNode : e.target;
    const page = parseInt(target.dataset.page, 0);
    this.setState({
      currentPage: page
    })

    this.props.onPageChange(page);
  }

  onShowMore = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: this.state.currentPage + 1
    })

    this.props.onShowMore(this.state.currentPage + 1);
  }

  render() {
    if(this.props.continousScrolling) {
      return <PaginationShowMore {...this.state} fetchingResults={this.props.fetchingResults} onShowMore={this.onShowMore} />
    } else {
      return <PaginationNumbers {...this.state} onPageChange={this.onPageChange} />
    }
  }
}

export default Pagination;
