let urlTool = {
  setQuery(key, value, router, parsedQuery) {
    let query = parsedQuery ? parsedQuery : new URLSearchParams(router.history.location.search)
    if((query.get(key) && query.get(key) !== value.toString()) || !query.get(key)) {
      query.set(key, value)
      router.history.push({...router.history.location, search: query.toString()})
    }
  },

  replaceQuery(key, value, router, parsedQuery) {
    let query = parsedQuery ? parsedQuery : new URLSearchParams(router.history.location.search)
    if((query.get(key) && query.get(key) !== value.toString()) || !query.get(key)) {
      query.set(key, value)
      router.history.replace({...router.history.location, search: query.toString()})
    }
  },

  buildCategoryUrl(demoName, categoryTitleInDemo, hash) {
    return `/${demoName}/${categoryTitleInDemo.toLowerCase()}/${hash}`;
  }
}

export default urlTool;
