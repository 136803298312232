import React, { Component } from 'react';
import { Tag } from 'antd';
import './DiagnosticToolbar.css';

class DiagnosticToolbar extends Component {
  render() {
    const { diagnosticSettings, diagnosticUser, overrideEngineUrl } = this.props;
    if (diagnosticSettings.diagnosticModeActive) {
      return <div className="diagnostic-toolbar">
        <div className="diagnostic-toolbar-top">
          <div className="toolbar-top-item diagnostic-active"><span>Diagnostic:</span> active</div>
          {overrideEngineUrl && <div className="toolbar-top-item oeu-active"><span>Override engine url:</span> {overrideEngineUrl}</div>}
          {diagnosticUser && <div className="toolbar-top-item user-active"><span>User Id:</span> {diagnosticUser.id}</div>}
          {diagnosticUser && <div className="toolbar-top-item user-intent-active"><span>User session intent:</span> {diagnosticUser.currentSessionIntent.map(i => i)}</div>}
        </div>
        {diagnosticUser &&
          <div className="diagnostic-user-info">
            <div className="user-preferences">
              <span className="user-preferences-title">User preferences:</span>{diagnosticUser.preferences.length}
              <div className="user-preferences-list">
                {diagnosticUser.preferences.map(pref =>
                  <div key={pref.item1} className="preference-container">
                    <div className="pref-container-name">{pref.item1}:</div>
                    <div className="pref-container-items">
                      {pref.item2.map(subPref =>
                        <Tag key={Math.random()} className="pref-container-item">
                          <span>{subPref.item1}</span>
                          <span>{subPref.item2}</span>
                        </Tag>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        {!diagnosticUser && <span style={{ padding: 10 }}>No user information is available at this moment</span>}
      </div>
    } else {
      return null;
    }
  }
}

export default DiagnosticToolbar;
