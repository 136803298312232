import React, { Component } from 'react';
import { Table } from 'antd';
import { BugOutlined } from '@ant-design/icons';
import './DiagnosticItemInfoTable.css';


class DiagnosticItemInfoTable extends Component {
  render() {
    const { item } = this.props;

    let allRows = [];
    item.attributes.forEach(attribute => {
      if (attribute.name === "Loop54Diagnostics") {
        for (var key in attribute.values[0]) {
          allRows.push(
            {
              renderElement:  <React.Fragment><BugOutlined /> {key}</React.Fragment>,
              value: String(attribute.values[0][key]),
              key: Math.random()
            }
          )
        }
      } else {
        var attributeRows = [];
        attributeRows.push(
          {
            renderElement: <span>{attribute.name}</span>,
            value: String(attribute.values),
            key: Math.random()
          }
        )
        attributeRows = attributeRows.sort((a, b) => (a.renderElement  > b.renderElement ) ? 1 : ((b.renderElement  > a.renderElement ) ? -1 : 0));
        allRows = allRows.concat(attributeRows);
      }
    });

    const columns = [
      {
        title: 'Name',
        dataIndex: 'renderElement',
        key: 'renderElement',
        width: 230
      },
      {
        title: 'Content',
        dataIndex: 'value',
        key: 'value',
      }
    ];

    return <Table className="diagnostic-item-info-table"
      bordered={true}
      pagination={false}
      dataSource={allRows}
      columns={columns} />
  }
}
export default DiagnosticItemInfoTable;
