import Attribute from "./attribute";

export const initializeProduct = (item, config) => {
  let stateItem = {};
  stateItem.diagnostics = findAndReturnAttributeData(item, "Loop54Diagnostics");
  stateItem.id = item.id;
  stateItem.amount = 1;
  stateItem.brand = findAndReturnAttributeData(item, "BRAND");
  stateItem.description = findAndReturnAttributeData(item, config.productDescriptionAttribute);
  stateItem.sale = findAndReturnAttributeData(item, config.saleAttribute, false);
  stateItem.salePrice = findAndReturnAttributeData(item, config.salePriceAttribute);
  stateItem.new = findAndReturnAttributeData(item, config.newAttribute, false);
  stateItem.imageUrl = Attribute.imageUrl(
    item,
    config.productImageUrl,
    config.productImageUrlAttributes
  );
  stateItem.title = findAndReturnAttributeData(item, config.productTitleAttribute, "No title found");
  stateItem.productLink = `/${config.name}/product/${encodeURIComponent(item.id)}`;
  stateItem.price = `${findAndReturnAttributeData(item, config.productPriceAttribute, "0")},-`;

  return stateItem;
};

function findAndReturnAttributeData(item, attribute, exceptionReturn = null) {
  let attributeExist = item.attributes.find(a => a.name === attribute);
  return attributeExist !== undefined ? attributeExist.values[0] : exceptionReturn;
}
