let storage = {
  setSession(key, value) {
    return sessionStorage.setItem(key, JSON.stringify(value))
  },
  getSession(key) {
    return JSON.parse(sessionStorage.getItem(key))
  },
  clearSession(key) {
    return sessionStorage.removeItem(key)
  }
}

export default storage;
