import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import DiagnosticCompareDrawer from '../diagnostic-compare-drawer/DiagnosticCompareDrawer'
import Events from '../../../utils/events';
import DiagnosticVisualizer from '../diagnostic-visualizer/DiagnosticVisualizer'

import './DiagnosticMultiSelectToolbar.css';

class DiagnosticMultiSelectToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diagnosticItemCompareDrawerOpen: false,
      diagnosticVisualizerDrawerOpen: false,
      visualizerType: null,
    }
  }

  componentDidUpdate() {
    const { multiSelectedItems } = this.props;
    if (multiSelectedItems && multiSelectedItems.length > 0) {
      document.addEventListener("keydown", this.escFunction, false);
    } else {
      document.removeEventListener("keydown", this.escFunction, false);
    }
  }

  compareSelectedItems() {
    this.setState({ diagnosticItemCompareDrawerOpen: true });
  }

  handleCloseDiagnosticItemCompareDrawer = () => {
    this.setState({ diagnosticItemCompareDrawerOpen: false });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    const { onClearMultiSelect } = this.props;
    //By checking if the event is target contains certain class names we can prevent clearing multiselect when pressing ECS in an modal.
    if (event.key === "Escape" && event.target.className !== "ant-modal-wrap") {
      onClearMultiSelect();
    }
  }

  sendEvent(eventType) {
    const { multiSelectedItems, config } = this.props;
    let events = multiSelectedItems.map(item => Events.buildEventObj(item.id, eventType));
    return Events.send(events, config.url);
  }

  getEventsMenu = () => {
    const { onAddNotification, config } = this.props;
    const menu = <Menu>
      <Menu.Item key="1" onClick={() => { this.sendEvent(config.addToCartEventType).then(() => { onAddNotification("Event sent", "Click event was sent", "success") }) }}>Add to cart event</Menu.Item>
      <Menu.Item key="1" onClick={() => { this.sendEvent(config.clickEventType).then(() => { onAddNotification("Event sent", "Click event was sent", "success") }) }}>Click event</Menu.Item>
      <Menu.Item key="2" onClick={() => { this.sendEvent(config.purchaseEventType).then(() => { onAddNotification("Event sent", "Purchase event was sent", "success") }) }}>Purchase event</Menu.Item>
    </Menu>;

    return menu;
  }

  visualizeSelectedItems = (visualizerType) => {
    this.setState({ diagnosticVisualizerDrawerOpen: true, visualizerType: visualizerType });
  }

  handleCloseDiagnosticVisualizerDrawer = () => {
    this.setState({ diagnosticVisualizerDrawerOpen: false });
  }

  renderVisualizerMenu = () => {
    return <Menu>
      <Menu.Item onClick={() => { this.visualizeSelectedItems("gas") }}>Gas Visualizer</Menu.Item>
      <Menu.Item onClick={() => { this.visualizeSelectedItems("golem") }}>Phat Visualizer</Menu.Item>
    </Menu>
  }


  render() {
    const { diagnosticSettings, multiSelectedItems, onClearMultiSelect, config, overrideEngineUrl } = this.props;
    return <React.Fragment>
      {this.state.diagnosticItemCompareDrawerOpen && <DiagnosticCompareDrawer config={config} multiSelectedItems={multiSelectedItems} diagnosticItemCompareDrawerOpen={this.state.diagnosticItemCompareDrawerOpen} onCloseDiagnosticItemCompareDrawer={this.handleCloseDiagnosticItemCompareDrawer}></DiagnosticCompareDrawer>}
      {this.state.diagnosticVisualizerDrawerOpen && <DiagnosticVisualizer visualizerType={this.state.visualizerType} overrideEngineUrl={overrideEngineUrl} diagnosticSettings={diagnosticSettings} config={config} multiSelectedItems={multiSelectedItems} diagnosticVisualizerDrawerOpen={this.state.diagnosticVisualizerDrawerOpen} onCloseDiagnosticVisualizerDrawer={this.handleCloseDiagnosticVisualizerDrawer}></DiagnosticVisualizer>}
      {multiSelectedItems.length > 0 && diagnosticSettings.diagnosticModeActive ? <div className="diagnostic-item-multiselect-actions-menu-container">
        <div className="diagnostic-item-multiselect-actions-menu">
          <div className="info-text">{multiSelectedItems.length} items selected</div>
          <div className="action-container">,
            <Dropdown overlay={this.renderVisualizerMenu()} placement="topCenter">
              <Button>Visualizer</Button>
            </Dropdown>
            <Dropdown overlay={this.getEventsMenu()} placement="topCenter">
              <Button>Send events</Button>
            </Dropdown>
            <Button onClick={() => { this.compareSelectedItems() }}>Compare</Button>
            <Button type="text" style={{ color: "#fff", marginLeft: "0.5rem" }} onClick={() => { onClearMultiSelect() }} icon={<CloseOutlined />}> Cancel selection</Button>
          </div>
        </div>
      </div> : null}
    </React.Fragment>
  }
}

export default DiagnosticMultiSelectToolbar;
