import React, { Component } from 'react';
import { Button, Table, Drawer } from 'antd';

import './AttributesList.css';

class AttributeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    }
  }

  _renderTable = () => {
    const { item, rawItem } = this.props;
    let rows = [];
    rawItem.attributes.forEach(attribute => {
      rows.push(
        {
          name: attribute.name,
          value:
          attribute.values.length > 1 ?
          attribute.values.map(res => <span key={res} style={{display:"block"}}>● <span style={{marginLeft:"0.2rem"}}>{res}</span></span>) :
          attribute.values,
          key: Math.random()
        }
      )
    });

    rows = rows.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    //Add following rows at the top of the table (i.e the attributes) so that they are not part of the sorting.
    rows.unshift(
      {
        name: "ExternalId",
        value: item.id,
        key: Math.random()
      }
    )
    rows.unshift(
      {
        name: "EntityType",
        value: rawItem.type,
        key: Math.random()
      }
    )

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Content',
        dataIndex: 'value',
        key: 'value',
      }
    ];

    return (
      <Table
        bordered={true}
        pagination={false}
        dataSource={rows}
        columns={columns} />
    )
  }

  render() {
    return <React.Fragment>
      <Button size="large" onClick={() => this.setState({ show: !this.state.show })}>Show Attributes</Button>
      <Drawer
        className="attribute-drawer"
        title="Product attributes"
        placement="right"
        closable={true}
        width="60vw"
        onClose={() => this.setState({ show: false })}
        visible={this.state.show}
      >
        {this._renderTable()}
      </Drawer>
    </React.Fragment>
  }
}

export default AttributeList;
