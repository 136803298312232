import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';

import handleDropdownOpenState from '../../utils/dropdownTool'
import urlTool from '../../utils/urlTool'

function getNumericalAndSortedNames(mappedBrandSubCategories) {
  // This following block removes the numerical names and then will be stored in numericalNames.
  let sortedNames = mappedBrandSubCategories.sort(e => e.name);
  let numericalNames;
  for (var i = 0; i < sortedNames.length; i++) {
    if (sortedNames[i].name.toLowerCase().startsWith("a")) {
      numericalNames = sortedNames.slice(0, i);
      sortedNames = sortedNames.slice(i, sortedNames.length);
      break;
    }
  }

  //following block group the brand names by first letter
  let groupedNames = sortedNames.reduce((r, e) => {
    let group = e.name[0].toLowerCase();
    if (!r[group]) r[group] = { group, children: [e] }
    else r[group].children.push(e);
    return r;
  }, {})

  groupedNames = Object.values(groupedNames);

  return { numericalNames, groupedNames };
}

class MenuBrandDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupedNames: null,
      renderedGroupedNames: null
    }
  }

  componentWillMount() {
    let groupedNames = this.groupNames();
    this.setState({ groupedNames: groupedNames });
    this.truncateChildrenForGroupedNames(groupedNames);
  }

  groupNames() {
    if (!this.props.mappedBrand || !this.props.mappedBrand.subCategories || this.props.mappedBrand.subCategories.length <= 0) {
      return null;
    }

    let { numericalNames, groupedNames } = getNumericalAndSortedNames(this.props.mappedBrand.subCategories);

    //Prepend the numerical values to the groupedNames
    if(numericalNames){
      groupedNames.unshift({
        group: "#",
        children: numericalNames
      });
    }

    return groupedNames;
  }

  truncateChildrenForGroupedNames(groupedNames, groupId = null) {
    if(!groupedNames || groupedNames.length <= 0){
      return;
    }

    let groupedNamesClone = JSON.parse(JSON.stringify(groupedNames));
    let processedGroupNames = groupedNamesClone.map(groupedName => {
      groupedName.originalChildrenLength = groupedName.children.length;
      groupedName.children = groupId && groupId === groupedName.group ? groupedName.children : groupedName.children.slice(0, 14);
      return groupedName;
    });

    let menuItems = this.renderMenuItems(processedGroupNames);

    this.setState({ renderedGroupedNames: menuItems });
  }

  renderMenuItems = (groupedNames) => {
    const { demoName, categoryTitleInDemo } = this.props;
    return <Menu className="brand-dropdown" id="brand-dropdown">
      <div className="brand-index-header">{groupedNames.map(g => <span onClick={() => { this.scrollNameGroupIntoView(g.group) }} key={g.group}>{g.group}</span>)}</div>
      {groupedNames.map(nameGroup =>
        <React.Fragment key={nameGroup.group}>
          <h3 id={`brand-index-${nameGroup.group}`} className="brand-heading">{nameGroup.group}</h3>
          <div className="brand-group">
            {
              nameGroup.children.map(brand => <a key={brand.hash} href={urlTool.buildCategoryUrl(demoName, categoryTitleInDemo, brand.hash)}>{brand.name}</a>)
            }
            {nameGroup.originalChildrenLength > 14 && nameGroup.originalChildrenLength !== nameGroup.children.length &&
              <span onClick={(e) => { this.loadMoreBrandsForGroup(nameGroup.group) }}>Show {nameGroup.originalChildrenLength - 14} more</span>}
          </div>
        </React.Fragment>
      )}
    </Menu>;
  }

  scrollNameGroupIntoView(groupIndexKey) {
    var dropdownScroll = document.getElementById("brand-dropdown").parentElement;
    var groupNameToScrollTo = document.getElementById(`brand-index-${groupIndexKey}`);
    //The -75 value is to scroll the section passed the index header.
    dropdownScroll.scrollTop = groupNameToScrollTo.offsetTop - 75;
  }

  handleOnShowState = (open) => {
    handleDropdownOpenState(open);
  }
  
  loadMoreBrandsForGroup(groupId) {
    this.truncateChildrenForGroupedNames(this.state.groupedNames, groupId);
  }

  render() {
    const { mappedBrand, dropDownMenuContainerRef } = this.props;
    if (this.state.renderedGroupedNames) {
      return (
        <Dropdown trigger="click" onVisibleChange={this.handleOnShowState} getPopupContainer={() => dropDownMenuContainerRef} overlay={this.state.renderedGroupedNames}>
          <Button size="large" type="text">Shop by {mappedBrand.titleInDemo}</Button>
        </Dropdown>
      );
    } else {
      return null;
    }
  }
}

export default MenuBrandDropdown;