import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, Layout, Button } from 'antd';
import { FilterFilled } from '@ant-design/icons';

import './Category.css';

import categoriesFormat from '../utils/categoriesFormat';
import Header from '../components/header/Header';
import SearchResults from '../components/results/SearchResults';
import Facets from '../components/facets/Facets';
import Pagination from '../components/pagination';
import Sort from '../components/sort';
import Footer from '../components/footer';

class Category extends Component {
  constructor(props) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    super(props);
    this.state = {
      showFilters: props.layout.filters === 'left' && vw > 992 ? true : false
    }
  }

  componentDidUpdate(prevProps) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (prevProps.layout !== this.props.layout && this.props.layout.filters === 'left' && vw > 992) {
      this.setState({ showFilters: true })
    }
  }

  toggleShowFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });

    //This scroll to top is for UX purpose.
    //When filters are on top of page, hiding/showing the filters will dissrupt the current scroll pos.
    window.scrollTo(0, 0);
  }

  render() {
    const { Content } = Layout;
    const { results, directResults, diagnosticSettings, config, layout, onBasketChange, onAddNotification, onItemSelected, multiSelectedItems } = this.props;
    let resultsWidth = 24;
    let directResultsWidth = 24;
    let directResultsColumns = 4;
    let layoutClassName = 'TMB';
    let breadcrumbs;

    // LRB (left right bottom)
    if (layout.filters === 'left') { resultsWidth = 18; directResultsColumns = 3; layoutClassName = 'LRB' }
    // TLR (top left right)
    if (layout.filters !== 'left') { directResultsWidth = 24; directResultsColumns = 4; layoutClassName = 'TLR' }

    if (diagnosticSettings.diagnosticModeActive) {
      directResultsWidth = 24;
      directResultsColumns = 8;
      layoutClassName = 'TMB'
    }

    if (this.props.categoryPath) {
      let categoryDelimeter = categoriesFormat.getCategoryDelimeter(this.props.categoryName, this.props.categories);
      let categoryPath = categoriesFormat.pathToObject(this.props.categoryPath, categoryDelimeter);
      let smallBreadcrumbs = [];
      if (categoryPath) {
        categoryPath.map((item, i) => {
          if (i === categoryPath.length - 1) {
            smallBreadcrumbs.push(<Breadcrumb.Item key={Math.random()}>{item} ({results && results.results.count})</Breadcrumb.Item>)
          } else {
            let itemObject = categoriesFormat.nameToObject(item, this.props.categories);
            if (itemObject[item]) {
              smallBreadcrumbs.push(<Breadcrumb.Item href={'/' + config.name + '/' + this.props.categoryName + '/' + itemObject[item].hash} key={Math.random()}>{item}</Breadcrumb.Item>)
            }
            // Demo only supports two levels of categories (see cateogoriesFormat.toObject), if the item is missing it may mean it's in a deeper category path
            // We used to crash on this, but we rather want to display these deeper (non-clickable) categories in the breadcrumbs
            else {
              smallBreadcrumbs.push(<Breadcrumb.Item key={i + item}>{item}</Breadcrumb.Item>)
            }
            
          }
          return true;
        })
      }

      breadcrumbs = (
        <Breadcrumb>
          <Breadcrumb.Item href={'/' + config.name}>Home</Breadcrumb.Item>
          {smallBreadcrumbs}
        </Breadcrumb>
      )
    }

    return (
      <Layout className={layoutClassName}>
        <Header {...this.props} />
        <Content>
          <Row className="category-row">
            <Facets {...this.props} showFilters={this.state.showFilters} />
            <Col lg={resultsWidth} md={24} sm={24} xs={24}>
              <Row>
                <Col lg={directResultsWidth} md={24} sm={24} xs={24} className="category-results-col">
                  <Row>
                    <Col span={24} className="result-list-header">
                      {results ? breadcrumbs : null}
                      {results ? <Button icon={<FilterFilled />} onClick={() => this.toggleShowFilters()} className="show-filters-button">{this.state.showFilters ? 'Hide filters' : 'Show filters'}</Button> : null}
                      <Sort config={config} resultsCount={directResults ? directResults.length : 0} onSortChange={this.props.onSortChange} sortAttribute={this.props.sortAttribute} sortDescending={this.props.sortDescending} />
                    </Col>
                  </Row>
                  <SearchResults
                    results={directResults ? directResults : []}
                    diagnosticSettings={diagnosticSettings}
                    columns={directResultsColumns}
                    config={config}
                    onBasketChange={onBasketChange}
                    onAddNotification={onAddNotification}
                    onItemSelected={onItemSelected}
                    multiSelectedItems={multiSelectedItems} />
                  <Pagination fetchingResults={this.props.fetchingResults} onPageChange={this.props.onPageChange} onShowMore={this.props.onShowMore} continousScrolling={this.props.config.continousScrolling} resultsCount={directResults ? directResults.length : 0} resultsMaxCount={results ? results.results.count : 0} currentPage={this.props.page} maxPage={results ? Math.ceil(results.results.count / this.props.config.directResultsPageSize) : 0} /> 
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
        <Footer></Footer>
      </Layout>
    );
  }
}

Category.contextTypes = {
  router: PropTypes.object
}

export default Category;
