import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import UrlTool from '../../utils/urlTool';

import './index.css';

class RelatedQueriesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  handleRelatedClick = (data) => {
    UrlTool.setQuery('q', data.query, this.context.router);
  }

  render() {
    let { relatedQueries } = this.props;

    if (!relatedQueries || relatedQueries.items.length === 0) {
      return null;
    }

    return (
      <div className="related-queries">
      <span className="related-queries-title">Suggested searches {'->'}</span>
        <div className="related-queries-list">
          {
            relatedQueries.items.map(item => {
              return <Tag onClick={() => this.handleRelatedClick(item)} key={Math.random()}>{item.query}</Tag>
            })
          }
        </div>
      </div>
    )
  }
}

RelatedQueriesIndex.contextTypes = {
  router: PropTypes.object
}

export default RelatedQueriesIndex;
