import React, { Component } from 'react';
import { Button, Drawer, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import urlTool from '../../utils/urlTool'

class MenuCategoryDrawerMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    }
  }

  getMenuItem() {
    const { SubMenu } = Menu;
    const { demoName, categoryTitleInDemo, mappedCategories } = this.props;
    return <Menu mode="inline">
      {mappedCategories.map(mappedCategory =>
        <SubMenu key={Math.random()} title={mappedCategory.name}>
          {mappedCategory.subCategories && mappedCategory.subCategories.map(subCat =>
            <Menu.Item key={Math.random()}>
              <a href={urlTool.buildCategoryUrl(demoName, categoryTitleInDemo, subCat.hash)}>{subCat.name}</a>
            </Menu.Item>)}
        </SubMenu>
      )}
    </Menu>
  }

  render() {
    const { categoryTitleInDemo } = this.props;
    return (
      <React.Fragment>
        <Button className="mobile-categories-dd-button" type="primary" icon={<MenuOutlined />} onClick={() => this.setState({ show: !this.state.show })}></Button>
        <Drawer
          className="category-drawer"
          title={categoryTitleInDemo}
          placement="left"
          closable={true}
          width="100vw"
          forceRender={true}
          onClose={() => this.setState({ show: false })}
          visible={this.state.show}
        >
          {this.getMenuItem()}
        </Drawer>
      </React.Fragment >
    );
  }
}

export default MenuCategoryDrawerMobile;
