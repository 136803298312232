import React, { Component } from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ResultItem from '../results/ResultItem';

class BasketCarousel extends Component {
  render() {
    const { config, onBasketChange, onAddNotification, diagnosticSettings, onItemSelected, multiSelectedItems, contextColors, diagnosticQuery } = this.props;

    const CustomBtnPrev = ({ currentSlide, slideCount, ...props }) => (
      <button {...props} className={classnames('slick-prev', currentSlide === 0 ? 'hidden' : null)}>
        <LeftOutlined />
      </button>
    );

    const CustomBtnNext = ({ currentSlide, slideCount, ...props }) => (
      <button {...props} className={classnames('slick-next', currentSlide === (slideCount - 2) ? 'hidden' : null)}>
        <RightOutlined />
      </button>
    );

    if (this.props.items && this.props.items.length > 0) {
      return (
        <div className="basket-rec-area">
          <span className="basket-bottom-area-text">Product recommendations:</span>
          <div className="items-rec-container">

            <Carousel className="results-card" dots={false} slidesToShow={2} arrows={true} infinite={false} draggable={false} nextArrow={<CustomBtnNext />} prevArrow={<CustomBtnPrev />}>
              {this.props.items.map((item) => {
                return ((
                  <div className="slide-item" key={item.id}>
                    <ResultItem
                      diagnosticSettings={diagnosticSettings}
                      item={item}
                      config={config}
                      onBasketChange={onBasketChange}
                      onAddNotification={onAddNotification}
                      onItemSelected={onItemSelected}
                      multiSelectedItems={multiSelectedItems}
                      contextColors={contextColors}
                      diagnosticQuery={diagnosticQuery}
                    />
                  </div>
                ))
              })}
            </Carousel>
          </div>
        </div>
      );
    }
    return (null);
  }
}

BasketCarousel.contextTypes = {
  router: PropTypes.object
}

export default BasketCarousel;