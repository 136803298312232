import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DemoWrapper from '../DemoWrapper';
import Product from './Product';
import BasketTool from '../utils/basketTool';
import Events from '../utils/events';
import loop54Client from '../utils/loop54Client';
import { initializeProduct } from '../utils/productInitialize'
import handleResponseError from '../utils/responseError';

class ProductContainer extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      externalId: null,
      item: null,
      rawItem: null,
      relatedProducts: [],
      complementaryProducts: []
    }
  }

  getExternalId = () => decodeURIComponent(this.context.router.route.match.params.externalId);

  componentDidMount() {
    var externalId = this.getExternalId()
    this.fetchProduct(this.getExternalId(externalId));
  }

  componentDidUpdate() {
    var externalId = this.getExternalId()
    if (this.state.externalId !== externalId) {
      this.fetchProduct(externalId);
    }
  }

  fetchProduct = (externalId) => {
    const { config } = this.props;
    if (externalId) {
      this.setState({
        externalId: externalId
      })

      var resultOptionsRequest = {
        take: 1,
        filter: {
          type: "id",
          value: String(externalId)
        }
      }

      loop54Client.getEntities(resultOptionsRequest).then(response => {
        const item = response.data.results.items[0];
        let stateItem = initializeProduct(item, config);
        this.setState({
          item: stateItem,
          rawItem: item
        })

        this.sendClickEvent(externalId);

        this.fetchRelatedAndComplementaryProducts(externalId);
      })
        .catch(response => handleResponseError(response));
    }
  }

  fetchRelatedAndComplementaryProducts = (externalId) => {
    const { diagnosticSettings } = this.props;

    var entityRequest = {
      type: "Product",
      id: String(externalId)
    }
    var resultOptionsRequest = {
      take: diagnosticSettings.diagnosticModeActive ? 8 : 4
    }

    loop54Client.getRelatedEntities(entityRequest, resultOptionsRequest).then(response => {
      this.setState({
        relatedProducts: response.data.results.items
      })
    });

    loop54Client.getComplementaryEntities(entityRequest, resultOptionsRequest).then(response => {
      this.setState({
        complementaryProducts: response.data.results.items
      })
    });
  }

  sendClickEvent(itemId) {
    const { config } = this.props;
    let event = Events.buildEventObj(itemId, config.clickEventType);
    return Events.send([event], config.url);
  }

  handleSearch = (item) => {
    var redirect = '/' + this.props.demoName + '/search?q=' + item.value;
    if (item.facet) { redirect = redirect + '&f=' + item.facet }
    this.context.router.history.push(redirect)
  }

  handlePurchaseClick = () => {
    BasketTool.addToPurchaseHistory(this.state.item, this.props.config.name, this.props.onBasketChange)
    let event = Events.buildEventObj(this.state.item.id, this.props.config.purchaseEventType);
    Events.send([event], this.props.config);
    this.props.onAddNotification('Purchase completed', `You have bought ${this.state.item.title}`, 'success')
  }

  handleAddToCartClick = () => {
    BasketTool.addToBasket(this.state.item, this.props.config.name, this.props.onBasketChange)
    let event = Events.buildEventObj(this.state.item.id, this.props.config.addToCartEventType);
    Events.send([event], this.props.config);
    this.props.onAddNotification('Product added to cart', `You have added ${this.state.item.title} to cart`, 'success')
  }

  handleChangeLayout = (layout) => {
    let newLayout = {};

    switch (layout) {
      case 'TLR':
        newLayout = { filters: 'top', related: 'right' }
        break;
      case 'TMB':
        newLayout = { filters: 'top', related: 'bottom' }
        break;
      case 'LRB':
        newLayout = { filters: 'left', related: 'bottom' }
        break;
      case 'LMR':
        newLayout = { filters: 'left', related: 'right' }
        break;
      default:
        break;
    }

    this.setState({
      layout: newLayout
    }, localStorage.setItem(this.props.config.name + '-layout', JSON.stringify(newLayout)))
  }

  render() {
    return (
      <Product
        {...this.state}
        {...this.props}
        onSearchFieldChange={this.handleSearch}
        onPurchaseClick={this.handlePurchaseClick}
        onAddToCartClick={this.handleAddToCartClick}
        onAddNotification={this.props.onAddNotification}
        onChangeLayout={this.handleChangeLayout}
      />
    );
  }
}

ProductContainer.contextTypes = {
  router: PropTypes.object
}

export default DemoWrapper(ProductContainer);