import React, { Component } from 'react';
import { Image, Button, Row, Col, Layout, Card } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';

import './Product.css';

import Header from '../components/header/Header';
import ResultItem from '../components/results/ResultItem';
import AttributeList from '../components/results/AttributeList';
import Footer from '../components/footer';
import Loader from '../components/loader';

class Product extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.item !== prevProps.item) {
      //This is for resetting the scroll bar to top after clicking on a related product
      window.scrollTo(0, 0);
    }
  }

  renderAdditionalProducts = (items, title) => {
    const { config, onBasketChange, onAddNotification, diagnosticSettings, onItemSelected, multiSelectedItems } = this.props;
    var result = [];
    var columns = [];
    if (items.length > 0) {
      result.push(
        <Row key={Math.random()}>
          <Col span={24}>
            <h2>{title}</h2>
          </Col>
        </Row>
      )

      items.map(product => {
        columns.push(
          <Col lg={diagnosticSettings.diagnosticModeActive ? 3 : 6} md={24} sm={24} xs={24} className="results-card" key={Math.random()}>
            <ResultItem
              diagnosticSettings={diagnosticSettings}
              item={product}
              config={config}
              onBasketChange={onBasketChange}
              onAddNotification={onAddNotification}
              onItemSelected={onItemSelected}
              multiSelectedItems={multiSelectedItems} />
          </Col>
        )
        return false;
      })

      result.push((
        <Row className="results-row" key={Math.random()}>
          {columns}
        </Row>
      ))
    }

    return result;
  }

  truncateString(str, num) {
    if (str && str.length <= num) {
      return str
    }
    return str ? str.slice(0, num) + '...' : '';
  }

  render() {
    const { Content } = Layout;
    const { item, onPurchaseClick, onAddToCartClick } = this.props;

    if (!item) {
      return (
        <Loader></Loader>
      )
    }

    return (
      <Layout>
        <Header {...this.props} />
        <Content>
          <Card className="single-product-card">
            <Row>
              <Col className="single-product-image-col" lg={10} md={24} sm={24} xs={24}>
                <Image src={item.imageUrl} />
              </Col>
              <Col className="single-product-details-col" lg={14} md={24} sm={24} xs={24}>
                {item.sale ? <div className="single-product-sale-tag">SALE</div> : null}
                {item.new ? <div className="single-product-new-tag">NEW</div> : null}
                {item.brand ? <div className="single-product-brand">{item.brand}</div> : null}
                <h1>{item.title}</h1>
                <div className="single-product-price">
                  {item.sale ? (
                    <React.Fragment>
                      <strike className="item-price-sale-original">{item.price}</strike> <span className="product-sales-price">{item.salePrice}</span>
                    </React.Fragment>
                  ) : (
                    item.price
                  )}
                </div>
                <p className="single-product-description" dangerouslySetInnerHTML={{ __html: this.truncateString(item.description, 300) }} />
                <div className="button-container">
                  <Button size="large" type="primary" onClick={onPurchaseClick} className="single-product-purchase-button">
                    Purchase
                  </Button>
                  <Button size="large" type="primary" onClick={onAddToCartClick} className="single-product-add-cart-button">
                    <ShoppingCartOutlined />
                  </Button>
                  <AttributeList item={this.props.item} rawItem={this.props.rawItem} />
                </div>
              </Col>
            </Row>
          </Card>
          {this.renderAdditionalProducts(this.props.relatedProducts, "Related Products")}
          {this.renderAdditionalProducts(this.props.complementaryProducts, "Complementary Products")}
        </Content>
        <Footer></Footer>
      </Layout>
    );
  }
}

export default Product;