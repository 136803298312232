import React, { Component } from 'react';
import { Drawer, Row, Col } from 'antd';
import './DiagnosticVisualizer.css';

import ResultItem from '../../results/ResultItem';

class DiagnosticVisualizer extends Component {
  generateVisualizerUrl() {
    const { multiSelectedItems, config, visualizerType, overrideEngineUrl } = this.props;
    let baseUrl = process.env.REACT_APP_ADMIN_URL;
    let itemIds = multiSelectedItems.map(i => i.id).join(",");
    let engineUrl = overrideEngineUrl ? overrideEngineUrl : config.url;
    return `${baseUrl}/${visualizerType}-visualizer/index.html#engineUrl=${engineUrl}&itemids=${itemIds}`;
  }

  _renderIframe = () => {
    let iframeUrl = this.generateVisualizerUrl();
    return <iframe title="visualizer" src={iframeUrl}></iframe>;
  }

  _renderItems = () => {
    const { multiSelectedItems, config, diagnosticSettings } = this.props;
    return multiSelectedItems && multiSelectedItems.map((item, i) => {
      return ((
        <Col span={24} className="results-card" key={Math.random()}>
          <ResultItem
            diagnosticSettings={diagnosticSettings}
            item={item}
            config={config}
            multiSelectedItems={multiSelectedItems}
          />
        </Col>
      ))
    })
  }

  closeDrawer() {
    this.props.onCloseDiagnosticVisualizerDrawer();
  }

  render() {
    const { visualizerType } = this.props;
    return <Drawer
      className="diagnostic-visualizer-drawer"
      title={`${visualizerType}-visualizer`.toLocaleUpperCase()}
      placement="right"
      closable={true}
      width="100vw"
      onClose={() => this.closeDrawer()}
      destroyOnClose={true}
      visible={this.props.diagnosticVisualizerDrawerOpen}
    >
      <Row>
        <Col span={20}>
          {this._renderIframe()}
        </Col>
        <Col className="visualizer-items diagnostic-mode-active" span={4}>
          <Row>
            {this._renderItems()}
          </Row>
        </Col>
      </Row>
    </Drawer>
  }
}

export default DiagnosticVisualizer;
