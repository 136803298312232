import { notification } from 'antd';

let responseError = {
  showMessage(title, messageToShow) {
    notification.error({
      bottom: 10,
      duration: 5,
      placement: 'bottomRight',
      message: title ? title : 'Something went wrong',
      description: messageToShow
    });
  },

  handleResponseError(error) {
    if (error.response && error.response.data && error.response.data.error) {
      responseError.showMessage(error.response.data.error.title, error.response.data.error.detail);
    } else if (error.data) {
      responseError.showMessage(null, error.data.error.title);
    } else {
      responseError.showMessage(null, "The request could not be processed");
    }
  }
}

export default responseError.handleResponseError;
