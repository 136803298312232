import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';

import handleDropdownOpenState from '../../utils/dropdownTool'
import urlTool from '../../utils/urlTool'

class MenuCategoryDropdown extends Component {
  getMenuItem(mappedCategory) {
    const { demoName, categoryTitleInDemo, subItemsMaxLength } = this.props;
    return <Menu>
      {mappedCategory.hash && <Menu.Item>
        <a className="category-dropdown-title" href={urlTool.buildCategoryUrl(demoName, categoryTitleInDemo, mappedCategory.hash)}>{mappedCategory.name}</a>
      </Menu.Item>}

      {mappedCategory.subCategories && mappedCategory.subCategories.slice(0, subItemsMaxLength).map(subCat =>
        <Menu.Item key={subCat.hash}>
          <a href={urlTool.buildCategoryUrl(demoName, categoryTitleInDemo, subCat.hash)}>{subCat.name}</a>
        </Menu.Item>)}

      {mappedCategory.subCategories && mappedCategory.hash && mappedCategory.subCategories.length > subItemsMaxLength && <Menu.Item key={Math.random()}>
        <a className="category-dropdown-view-all" href={urlTool.buildCategoryUrl(demoName, categoryTitleInDemo, mappedCategory.hash)}>View all categories for {mappedCategory.name}</a>
      </Menu.Item>}
    </Menu>;
  }

  handleOnShowState = (open) => {
    handleDropdownOpenState(open);
  }

  render() {
    const { demoName, categoryTitleInDemo, mappedCategory, dropDownMenuContainerRef } = this.props;
    return (
      <Dropdown trigger="click" disabled={!mappedCategory.subCategories || mappedCategory.subCategories.length === 0} onVisibleChange={this.handleOnShowState} getPopupContainer={() => dropDownMenuContainerRef} key={Math.random()} overlay={this.getMenuItem(mappedCategory)}>
        <Button href={urlTool.buildCategoryUrl(demoName, categoryTitleInDemo, mappedCategory.hash)} size="large" type="text">{mappedCategory.name}</Button>
      </Dropdown>
    );
  }
}

export default MenuCategoryDropdown;
