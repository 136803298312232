import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'url-search-params-polyfill';

import DemoWrapper from '../DemoWrapper';
import Category from './Category';

import categoriesFormat from '../utils/categoriesFormat';
import UrlTool from '../utils/urlTool';
import isEqual from '../utils/compare';
import Storage from '../utils/storage';
import loop54Client from '../utils/loop54Client';
import handleResponseError from '../utils/responseError';

class CategoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      categoryPath: null,
      categoryName: null,
      selectedFacets: {},
      sortAttribute: 'relevance',
      sortDescending: 'false',
      results: null,
      directResults: [],
      relatedResults: [],
      fetchingResults: false,
      layout: { filters: 'top', related: 'bottom' }
    }
  }

  componentDidMount() {
    let newState = {};
    let query = new URLSearchParams(this.context.router.route.location.search);
    if (query.get('p')) {
      newState.page = parseInt(query.get('p'), 0)
    }

    const categoryName = this.context.router.route.match.params.categoryName;
    const categoryHash = this.context.router.route.match.params.categoryHash;
    newState.categoryName = categoryName;

    let searchStorage = Storage.getSession(this.props.config.name + '-categoryState');

    if (searchStorage && searchStorage.categoryHash === categoryHash) {
      newState.selectedFacets = searchStorage.selectedFacets
      newState.sortAttribute = searchStorage.sortAttribute
      newState.sortDescending = searchStorage.sortDescending
    }

    let layoutStorage = localStorage.getItem(this.props.config.name + '-layout');
    if (layoutStorage) {
      newState.layout = JSON.parse(layoutStorage);
    }

    this.setState(newState);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const categoryHash = nextContext.router.route.match.params.categoryHash;
    let categoryPath = categoriesFormat.hashToPath(categoryHash, nextState.categoryName, nextProps.categories);

    let query = new URLSearchParams(nextContext.router.route.location.search);

    if ((!nextState.results && categoryPath) ||
      (isEqual(this.state.results, nextState.results) && (this.state.page !== nextState.page)) ||
      (this.state.sortAttribute !== nextState.sortAttribute || this.state.sortDescending !== nextState.sortDescending)) {
      let facet = null;
      if (!isEqual(this.state.selectedFacets, nextState.selectedFacets) &&
        Object.keys(nextState.selectedFacets).length === 1 &&
        nextState.selectedFacets[this.props.config.autoCompleteFacetingParameter] &&
        nextState.selectedFacets[this.props.config.autoCompleteFacetingParameter].length === 1
      ) {
        facet = nextState.selectedFacets[this.props.config.autoCompleteFacetingParameter][0];
      }

      let categoryObject = { value: categoryPath, facet: facet, page: nextState.page - 1, sortAttribute: nextState.sortAttribute, sortDescending: nextState.sortDescending }
      this.getCategory(categoryObject, null, query, nextState, null, nextProps);
    }
  }

  getCategory = (item, facets, parsedQuery, nextState, manualUpdate, nextProps) => {
    let state = nextState ? nextState : this.state;
    let props = nextProps ? nextProps : this.props;

    if (!state.fetchingResults && props.categories.length > 0) {
      this.setState({
        fetchingResults: true
      })

      let selectedFacets = {};
      let sortAttribute, sortDescending = false;
      let query = parsedQuery ? parsedQuery : new URLSearchParams(this.context.router.route.location.search);
      let requestPage = typeof (item.page) !== 'undefined' ? item.page : query.get('p') ? parseInt(query.get('p'), 0) - 1 : 0;


      // if facet was present in the URL (i.e. it's a search from another page) this will trigger
      if (item.facet) {
        selectedFacets[this.props.config.autoCompleteFacetingParameter] = [item.facet];
        query.delete('f')
        this.context.router.history.replace({ ...this.context.router.history.location, search: query.toString() })
        this.handlePageChange(1);
        requestPage = 0;
      }

      // if facets are sent from facets component (i.e. a facet has been selected in a facet box or range facet has been dragged) this will trigger
      if (facets && Boolean(Object.keys(facets).length)) {
        selectedFacets = facets;
        this.handlePageChange(1);
        requestPage = 0;
      }

      if (!manualUpdate && !Boolean(Object.keys(selectedFacets).length) && Boolean(Object.keys(state.selectedFacets).length)) {
        selectedFacets = this.state.selectedFacets;
      }

      if (item.sortAttribute) {
        if (item.sortAttribute !== 'relevance') {
          sortAttribute = item.sortAttribute;
          sortDescending = item.sortDescending;
        }
      } else if (this.state.sortAttribute !== 'relevance') {
        sortAttribute = this.state.sortAttribute;
        sortDescending = this.state.sortDescending;
      }

      // MANUAL PARAMS PRESENT
      if (typeof (item.requestPage) !== 'undefined') {
        requestPage = item.requestPage;
      }

      if (item.selectedFacets) {
        selectedFacets = item.selectedFacets;
      }

      if (item.sortAttribute) {
        sortAttribute = item.sortAttribute;
        sortDescending = item.sortDescending;
      }

      let sessionObject = {
        categoryPath: item.value,
        categoryHash: this.context.router.route.match.params.categoryHash,
        selectedFacets: selectedFacets,
        sortAttribute: sortAttribute ? sortAttribute : this.state.sortAttribute,
        sortDescending: sortDescending
      }

      Storage.setSession(this.props.config.name + '-categoryState', sessionObject);

      let req = categoriesFormat.request(
        item,
        selectedFacets,
        {
          config: this.props.config,
          category: props.categories.find(c => { return c.titleInDemo.toLowerCase() === state.categoryName }),
          page: requestPage,
          sortAttribute: sortAttribute,
          sortDescending: Boolean(sortDescending)
        });

      this.setState({ categoryPath: item.value, selectedFacets: selectedFacets })

      loop54Client.getEntitiesByAttribute(req.attribute.name, req.attribute.value, req.resultOptions).then(response => {
        let parsedResponse = categoriesFormat.response(response);
        this.setState({
          fetchingResults: false,
          results: parsedResponse,
          directResults: parsedResponse.results.items
        })
      })
      .catch(response => handleResponseError(response));
    }
  }

  handlePageChange = (page) => {
    this.setState({
      page: page
    })
    UrlTool.setQuery('p', page, this.context.router);
  }

  handleShowMore = (page) => {
    this.setState({
      page: page
    })
    UrlTool.setQuery('p', page, this.context.router);
  }


  handleSortChange = (e, data) => {
    let sort = data.value.split('>')
    if (sort.length > 1 && sort[1] === 'DESC') {
      this.setState({
        sortAttribute: sort[0],
        sortDescending: true
      })
    } else {
      this.setState({
        sortAttribute: sort[0],
        sortDescending: false
      })
    }
  }

  handleSelectedFacetsChange = (selectedFacets) => {
    this.getCategory({ value: this.state.categoryPath }, selectedFacets, null, null, true)
  }

  handleSearchFieldChange = (item, facets) => {
    var redirect = '/' + this.props.demoName + '/search?q=' + item.value;
    if (item.facet) { redirect = redirect + '&f=' + item.facet }
    this.context.router.history.push(redirect)
  }

  handleReset = () => {
    this.props.onReset();
    UrlTool.replaceQuery('p', 1, this.context.router);
    this.setState({
      page: 1,
      selectedFacets: {},
      sortAttribute: 'relevance',
      sortDescending: 'false'
    }, this.getCategory({ value: this.state.categoryPath, requestPage: 0 }, null, null, null, true))
  }

  handleChangeLayout = (layout) => {
    let newLayout = {};

    switch (layout) {
      case 'TLR':
        newLayout = { filters: 'top', related: 'right' }
        break;
      case 'TMB':
        newLayout = { filters: 'top', related: 'bottom' }
        break;
      case 'LRB':
        newLayout = { filters: 'left', related: 'bottom' }
        break;
      case 'LMR':
        newLayout = { filters: 'left', related: 'right' }
        break;
      default:
        break;
    }

    this.setState({
      layout: newLayout
    }, localStorage.setItem(this.props.config.name + '-layout', JSON.stringify(newLayout)))
  }

  render() {
    return (
      <Category
        {...this.state}
        {...this.props}
        search={this.handleSearchFieldChange}
        onSelectedFacetsChange={this.handleSelectedFacetsChange}
        onSearchFieldChange={this.handleSearchFieldChange}
        onPageChange={this.handlePageChange}
        onShowMore={this.handleShowMore}
        onSortChange={this.handleSortChange}
        onBasketChange={this.props.onBasketChange}
        onReset={this.handleReset}
        onChangeLayout={this.handleChangeLayout}
      />
    );
  }
}

CategoryContainer.contextTypes = {
  router: PropTypes.object
}

export default DemoWrapper(CategoryContainer);
