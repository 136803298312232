import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { notification, BackTop, Button, Tooltip } from 'antd';
import { UpOutlined } from '@ant-design/icons';

import BasketTool from './utils/basketTool';
import isEqual from './utils/compare';
import loop54Client from './utils/loop54Client';
import CategoriesFormat from './utils/categoriesFormat';
import Storage from './utils/storage';
import Footer from './components/footer';
import Loader from './components/loader';
import DiagnosticToolbar from './components/diagnostic/diagnostic-toolbar/DiagnosticToolbar';
import DiagnosticMultiSelectToolbar from './components/diagnostic/diagnostic-multiselect-toolbar/DiagnosticMultiSelectToolbar';

function toCamelCase(response) {
  if (Array.isArray(response)) {
    return response.map(toCamelCase);
  } else if (typeof response === 'object') {
    const result = {};
    for (const key in response) {
      result[`${key.substr(0, 1).toLowerCase()}${key.substr(1)}`] = toCamelCase(
        response[key]
      );
    }
    return result;
  } else {
    return response;
  }
}

function DemoWrapper(WrappedComponent) {
  const diagnosticSettingsLocalStorage = {
    remove: (demoName) => { localStorage.removeItem(demoName + '-diagnosticSettings') },
    set: (demoName, data) => { localStorage.setItem(demoName + '-diagnosticSettings', JSON.stringify(data)) },
    get: (demoName) => { return JSON.parse(localStorage.getItem(demoName + '-diagnosticSettings')) }
  };

  const colorsLocalStorage = {
    remove: (demoName) => { localStorage.removeItem(demoName + '-colors') },
    set: (demoName, data) => { localStorage.setItem(demoName + '-colors', JSON.stringify(data)) },
    get: (demoName) => { return JSON.parse(localStorage.getItem(demoName + '-colors')) }
  };

  const diagnosticUserLocalStorage = {
    remove: (demoName) => { localStorage.removeItem(demoName + '-diagnosticUser') },
    set: (demoName, data) => { localStorage.setItem(demoName + '-diagnosticUser', JSON.stringify(data)) },
    get: (demoName) => { return JSON.parse(localStorage.getItem(demoName + '-diagnosticUser')) }
  };

  return class extends Component {
    static contextTypes = {
      router: PropTypes.object
    }

    constructor(props) {
      super(props);

      this.state = {
        demoName: 'HelloWorld',
        config: null,
        online: false,
        loading: true,
        error: null,
        categories: [],
        shoppingbasket: { items: [], total: 0 },
        colors: null,
        diagnosticSettings: { diagnosticModeActive: false, customCardAttributes: [] },
        overrideEngineUrl: null,
        multiSelectedItems: [],
        diagnosticUser: null
      }
    }

    componentWillMount() {
      if (this.props.location.pathname)
        this.setDemoName(this.props);
    }

    componentWillReceiveProps(nextProps) {
      this.setDemoName(nextProps);
    }

    componentWillUpdate(nextProps, nextState) {
      if (nextState.config) {
        let basket = BasketTool.getBasket(nextState.config.name);
        if (!isEqual(nextState.shoppingbasket, basket)) {
          this.setState({
            shoppingbasket: basket
          })
        }
      }
    }

    componentWillUnmount() {
      this.removeNavScrollEvent();
    }

    componentDidMount() {
      this.checkIfOverrideEngineUrlQueryParamIsActive();
      this.checkIfDiagnosticQueryParamIsActive();
      this.getOverrideEngineUrlFromSessionStorageAndApply();
      this.getColorsFromStorageAndApply();
      this.getDiagnosticSettingsFromStorageAndApply();
      this.getDiagnosticUserFromStorageAndApply();
      this.setupNavScrollEvent();

      new Promise(resolve => {
        let result = this.loadDemoConfig(this.state.demoName);
        if (result) {
          result.then(res => resolve(res))
        } else {
          resolve(false)
        }
      }).then(config => {
        if (config) {
          CategoriesFormat.getCategoriesState(config)
          .then(categoriesState => {
            this.setState({
              categories: categoriesState
            })
          })
          .catch((e) => {
            console.log('Did not load categories', e);
          })
        }
      })
    }

    setupNavScrollEvent() {
      this.checkHeaderOffset();
      window.addEventListener('scroll', this.checkHeaderOffset, true);
    }

    removeNavScrollEvent() {
      localStorage.setItem('lastScrollTop', 0);
      window.removeEventListener('scroll', this.checkHeaderOffset, true);
    }

    checkHeaderOffset() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var element = document.getElementById("demo-header");
      if (element && st > JSON.parse(localStorage.getItem('lastScrollTop'))) {
        window.pageYOffset > 100 && !element.classList.contains("category-dd-active") && element && element.classList.add("shrink-header");
      } else {
        element && element.classList.remove("shrink-header");
      }
      localStorage.setItem('lastScrollTop', st <= 0 ? 0 : st);
    }

    checkIfOverrideEngineUrlQueryParamIsActive = () => {
      var query = new URLSearchParams(this.context.router.route.location.search);
      //oeu = override engine url
      let url = query.get("oeu");
      if (url) {
        query.delete("oeu");
        this.context.router.history.replace({ ...this.context.router.history.location, search: query.toString() });
        let diagnosticSettings = this.state.diagnosticSettings;
        diagnosticSettings.diagnosticModeActive = true;
        this.handleDiagnosticSettingsChange(diagnosticSettings, false);

        let overrideEngineUrl = this.state.overrideEngineUrl;
        overrideEngineUrl = url;
        this.handleOverrideEngineUrl(overrideEngineUrl, false);
      }
    }

    checkIfDiagnosticQueryParamIsActive = () => {
      var query = new URLSearchParams(this.context.router.route.location.search);
      let url = query.get("diagnostic");
      if (url) {
        query.delete("diagnostic");
        this.context.router.history.replace({ ...this.context.router.history.location, search: query.toString() });
        this.handleDiagnosticSettingsChange({...this.state.diagnosticSettings, diagnosticModeActive: true}, false);
      }
    }

    setDemoName = (props) => {
      if (props.match.params.demoName && props.match.params.demoName !== this.state.demoName) {
        const savedCustomCardAttributes = diagnosticSettingsLocalStorage.get(props.match.params.demoName);
        const customCardAttributesLS = savedCustomCardAttributes 
        ? savedCustomCardAttributes.customCardAttributes
        : [{id: 1, name: "", attribute: "d.localPopularity"},
          {id: 2, name: "", attribute: "d.globalPopularity"},
          {id: 3, name: "", attribute: "d.boostMultiplier"},
          {id: 4, name: "", attribute: "d.relevance"},]   
        this.setState({
          loading: true,
          demoName: props.match.params.demoName,
          diagnosticSettings: {customCardAttributes: customCardAttributesLS},
        })
      }
    }

    handleReset = () => {
      this.setState({
        shoppingbasket: BasketTool.newBasket(),
        diagnosticUser: null
      })
      BasketTool.emptyBasket(this.state.demoName);

      diagnosticUserLocalStorage.remove(this.state.demoName);

      loop54Client.generateNewUserId();
    }

    handleBasketChange = (basket) => {
      this.setState({
        shoppingbasket: basket
      })
    }

    handleAddNotification = (messageTitle, messageDescription, level) => {
      notification[level]({
        message: messageTitle,
        description: messageDescription,
        placement: "bottomRight",
        duration: 4
      });
    }

    handleDiagnosticSettingsChange = (diagnosticSettings, reload = false) => {
      diagnosticSettingsLocalStorage.set(this.state.demoName, diagnosticSettings);

      if (reload) {
        this.context.router.history.push("/");
        this.context.router.history.goBack();
      }

      this.setState({ diagnosticSettings: { ...diagnosticSettings } });
    }

    handleOverrideEngineUrl = (url, reload = false) => {
      Storage.setSession(this.state.demoName + '-overrideEngineUrl', url);

      if (reload) {
        this.context.router.history.push("/");
        this.context.router.history.goBack();
      }

      this.setState({ overrideEngineUrl: url });
    }

    getDiagnosticSettingsFromStorageAndApply() {
      const diagnosticSettings = diagnosticSettingsLocalStorage.get(this.state.demoName);
      if (diagnosticSettings) {
        this.setState({ diagnosticSettings: diagnosticSettings });
      }
    }

    getOverrideEngineUrlFromSessionStorageAndApply() {
      const overrideEngineUrl = Storage.getSession(this.state.demoName + '-overrideEngineUrl');
      if (overrideEngineUrl) {
        this.setState({ overrideEngineUrl: overrideEngineUrl });
      }
    }

    onChangeDesign = (colors, clear = false) => {
      if (colors && !clear) {
        colorsLocalStorage.set(this.state.demoName, colors);
        this.setState({ colors: colors });
        this.applyColorsToDom(colors);

      } else if (clear) {
        colorsLocalStorage.remove(this.state.demoName);
        this.setState({ colors: null });
        this.applyColorsToDom(colors);
      }
    }

    getColorsFromStorageAndApply() {
      const colors = colorsLocalStorage.get(this.state.demoName);
      this.setState({ colors: colors });
      this.applyColorsToDom(colors);
    }

    applyColorsToDom(colors) {
      for (var colorProp in colors) {
        if (Object.prototype.hasOwnProperty.call(colors, colorProp) && colorProp !== "headerImageUrl") {
          document.documentElement.style.setProperty(`--loop54-${colorProp}-color`, colors[colorProp]);
        }
      }
    }

    handleItemSelected = (item) => {
      var multiSelectedItems = this.state.multiSelectedItems;
      let index = multiSelectedItems.indexOf(item);
      if (index !== -1) {
        multiSelectedItems.splice(index, 1);
      } else {
        multiSelectedItems.push(item);
      }

      this.setState({ multiSelectedItems: multiSelectedItems });
    }

    handleClearMultiSelect = () => {
      this.setState({ multiSelectedItems: [] })
    }

    handleSetDiagnosticUser = (diagnosticUser) => {
      this.setState({ diagnosticUser: diagnosticUser });
      diagnosticUserLocalStorage.set(this.state.demoName, diagnosticUser)
    }

    getDiagnosticUserFromStorageAndApply() {
      const diagnosticUser = diagnosticUserLocalStorage.get(this.state.demoName);
      this.setState({ diagnosticUser: diagnosticUser });
    }

    loadDemoConfig = (name) => {
      if (!name) { return false }

      return Axios.get(process.env.REACT_APP_ADMIN_API_URL + '/Demos/actions/GetByName?name=' + name).then(response => {
        const data = toCamelCase(response.data);
        this.setState({ config: data })

        let overrideEngineUrl = this.state.diagnosticSettings.diagnosticModeActive && this.state.overrideEngineUrl;

        let url = overrideEngineUrl ? overrideEngineUrl : data.url;

        if (url) {
          loop54Client.setClient(url, this.state.diagnosticSettings);

          Axios.get(data.url, { timeout: 4000 }).then(response => {
            if (response.status === 200) {
              this.setState({ loading: false, error: null })
            }
          }).catch(error => {
            var errorMessageObj = this.buildErrorMessage(error, "engine");
            this.setState({ loading: false, error: errorMessageObj })
          })
        } else {
          this.setState({ loading: false, error: null })
        }
        return data;
      }).catch(error => {
        var errorMessageObj = this.buildErrorMessage(error, "demoConfig");
        this.setState({ loading: false, error: errorMessageObj })
        return false;
      })
    }

    buildErrorMessage(error, origin) {
      var errorLog = {
        statusMessage: null,
        code: null,
        origin: origin
      }

      if (error.response) {
        errorLog.code = error.response.status;
      }

      if (error.message) {
        errorLog.statusMessage = error.message;
      }

      // The response contains the most detailed error message so display that one
      if (error.response && error.response.data && error.response.data.Message) {
        errorLog.statusMessage = error.response.data.Message;
      }

      return errorLog;
    }

    renderLoadingScreen = () => {
      return (
        <Loader></Loader>
      )
    }

    renderErrorScreen = () => {
      const { error, demoName, config } = this.state;
      var message;

      if(error.origin === "demoConfig"){
        message = <span>Something went wrong when loading the demo <b>"{demoName}"</b></span>;
        if(error.code === 404) message = <span>There is no demo with the name <b>"{demoName}"</b></span>;
        if(error.code === 408) message = <span>The request for demo<b>"{demoName}"</b> timed out</span>;
      }

      if(error.origin === "engine"){
        message = "Something went wrong when loading the engine";
        if(error.code === 404) message = "The requested engine does not exist";
        if(error.code === 408) message = "The requested engine timed out";
        if(error.statusMessage.includes("timeout")) message = "Engine does not respond";
      }

      var httpProtocolMessage = window.location.protocol === "https:" && config && (config.url.indexOf("http://") === 0)  ? 
        <span className="protocol-error">
          It may require mixed content to be enabled. Check the JavaScript console by pressing F12 - if it contains an error starting with "Mixed Content" then <br />
          <a href={"https://stackoverflow.com/questions/18321032/how-to-get-chrome-to-allow-mixed-content"}> try enabling mixed content in your browser</a>
        </span> : null;
     
      return (
        <div className='no-page'>
          <div className="no-page-message-logo"></div>
          <div className="no-page-message-wrapper">
            <div className="no-page-message-modal">
              <h1 className="error-code"><b>{error.code}</b></h1>
              <h1>{message}</h1>
              <span>{error.statusMessage}</span>
              { httpProtocolMessage }
            </div>
          </div>
          <Footer></Footer>
        </div>
      )
    }

    render() {
      const { error, loading, diagnosticSettings, multiSelectedItems, diagnosticUser, overrideEngineUrl } = this.state;
      return (
          <div className={diagnosticSettings.diagnosticModeActive ? 'diagnostic-mode-active' : undefined}>
            <DiagnosticToolbar diagnosticSettings={diagnosticSettings} diagnosticUser={diagnosticUser} overrideEngineUrl={overrideEngineUrl}></DiagnosticToolbar>
            {error ? this.renderErrorScreen() : null}
            {!error && loading ? this.renderLoadingScreen() : null}
            {!error && !loading ?
              <WrappedComponent
                {...this.state}
                onReset={this.handleReset}
                onBasketChange={this.handleBasketChange}
                onAddNotification={this.handleAddNotification}
                onChangeDiagnosticSettings={this.handleDiagnosticSettingsChange}
                onChangeOverrideEngineUrl={this.handleOverrideEngineUrl}
                onChangeDesign={this.onChangeDesign}
                onItemSelected={this.handleItemSelected}
                onSetDiagnosticUser={this.handleSetDiagnosticUser}
              /> : null
            }
            <BackTop>
              <Tooltip title="To the top" placement="left">
                <Button type="primary" shape="circle" icon={<UpOutlined />} size="large" />
              </Tooltip>
            </BackTop>
            <DiagnosticMultiSelectToolbar
              config={this.state.config}
              diagnosticSettings={diagnosticSettings}
              overrideEngineUrl={overrideEngineUrl}
              multiSelectedItems={multiSelectedItems}
              onClearMultiSelect={this.handleClearMultiSelect}
              onAddNotification={this.handleAddNotification}
            ></DiagnosticMultiSelectToolbar>
          </div>
      );
    }
  }
}

DemoWrapper.contextTypes = {
  router: PropTypes.object
}

export default DemoWrapper;
