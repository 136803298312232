import React, { Component } from 'react';
import { Row, Col } from 'antd';

import './SearchResults.css';

import ResultItem from './ResultItem';

class SearchResults extends Component {

  render() {
    const { results, config, onBasketChange, onAddNotification, diagnosticSettings, onItemSelected, multiSelectedItems, contextColors, diagnosticQuery } = this.props;
    let columns = 24 / this.props.columns;

    if (!columns) { columns = 24 / 3 }

    return (
      <Row className="results-row">
        {results && results.map((item) => {
          return ((
            <Col xl={columns} lg={6} md={8} sm={12} xs={24} className="results-card" key={item.id}>
              <ResultItem
                diagnosticSettings={diagnosticSettings}
                item={item}
                config={config}
                onBasketChange={onBasketChange}
                onAddNotification={onAddNotification}
                onItemSelected={onItemSelected}
                multiSelectedItems={multiSelectedItems}
                contextColors={contextColors}
                diagnosticQuery={diagnosticQuery}
              />
            </Col>
          ))
        })}
      </Row>
    );
  }
}

export default SearchResults;
