import React, { Component } from 'react';
import { Row, Col, Card, List, Tooltip } from 'antd';
import ReactJson from 'react-json-view'
import './DiagnosticQueryInfoPanel.css';

class DiagnosticQueryInfoPanel extends Component {
  truncateString(str, num) {
    if (str && str.length <= num) {
      return str
    }
    return str ? str.slice(0, num) + '...' : '';
  }

  render() {
    const { diagnosticQuery, contextColors } = this.props;

    if (diagnosticQuery) {
      return <div className="diagnostic-query-info-panel">
        <Row>
          <Col span={6}>
            <Card className="diagnostic-query-card" title="Query information">
              <List>
                <List.Item className="diagnostic-query-list-item" key="1">
                  <span className="prop-name">Classification:</span>
                  <span className="prop-value">{String(diagnosticQuery.classification)}</span>
                </List.Item>
                <List.Item className="diagnostic-query-list-item" key="2">
                  <span className="prop-name">HasSessionIntent:</span>
                  <span className="prop-value">{String(diagnosticQuery.hasSessionIntent)}</span>
                </List.Item>
                <List.Item className="diagnostic-query-list-item" key="3">
                  <span className="prop-name">IsSkuMatch:</span>
                  <span className="prop-value">{String(diagnosticQuery.isSkuMatch)}</span>
                </List.Item>
                <List.Item className="diagnostic-query-list-item" key="4">
                  <span className="prop-name">Learnt:</span>
                  <span className="prop-value">{String(diagnosticQuery.learnt)}</span>
                </List.Item>
                <List.Item className="diagnostic-query-list-item" key="5">
                  <span className="prop-name">Popularity:</span>
                  <span className="prop-value">{String(diagnosticQuery.popularity)}</span>
                </List.Item>
                <List.Item className="diagnostic-query-list-item" key="6">
                  <span className="prop-name">PreferencesExist:</span>
                  <span className="prop-value">{String(diagnosticQuery.preferencesExist)}</span>
                </List.Item>
              </List>
            </Card>
          </Col>
          <Col span={9}>
            <Card className="diagnostic-query-card" title="Context distribution">
              <List>
                {contextColors.map(item =>
                  <List.Item key={item.id}>
                    <div style={{ width: "25%" }} className="diagnostic-query-list-item">
                      <span className="prop-name"><span className="color-box" style={{ backgroundColor: item.color }}></span> Rank</span>
                      <span className="prop-value">{item.rank}</span>
                    </div>
                    <div style={{ width: "25%" }} className="diagnostic-query-list-item">
                      <span className="prop-name">Id:</span>
                      <span className="prop-value">{item.id}</span>
                    </div>
                    <div style={{ width: "25%" }} className="diagnostic-query-list-item">
                      <span className="prop-name">Label:</span>
                      <span className="prop-value"><Tooltip title={item.Label}>{this.truncateString(item.label, 50)}</Tooltip></span>
                    </div>
                    <div style={{ width: "25%" }} className="diagnostic-query-list-item">
                      <span className="prop-name">Probability:</span> <span className="prop-value">{item.probability.toFixed(5)}</span>
                    </div>
                  </List.Item>
                )}
              </List>
            </Card>
          </Col>
          <Col span={9}>
            <Card className="diagnostic-query-card" title="Query collection distribution">
              <ReactJson src={diagnosticQuery.queryCollection} displayDataTypes={false} displayObjectSize={false} enableClipboard={false}/>
            </Card>
          </Col>
        </Row>
      </div>
    } else {
      return null;
    }
  }
}

export default React.memo(DiagnosticQueryInfoPanel);
