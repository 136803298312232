import React, { Component } from 'react';
import { Button, Dropdown, Menu, Badge } from 'antd';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { sumBy } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import Img from 'react-image';
import PropTypes from 'prop-types';
import handleDropdownOpenState from '../../utils/dropdownTool'

import PlaceholderImage from '../../images/placeholder-small.png';

class PurchaseHistoryIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyOpen: false,
    }
  }

  toggleDropDown(forceShut = false) {
    const value = forceShut ? false : !this.state.historyOpen;
    this.setState({ historyOpen: value });
    handleDropdownOpenState(value);
  }

  handleProductClick = (item) => {
    this.toggleDropDown(true);
    this.context.router.history.push(item.productLink);
  }

  renderHistoryDropdown = () => {
    const menu = <Menu>
      <div className="basket-header">
        Your purchase history
        <Button type="text" icon={<CloseOutlined />} onClick={() => this.toggleDropDown()}></Button>
      </div>
      {this.props.basket && this.props.basket.historyItems.map(item => {
        return (
          <Menu.Item key={Math.random()} className="basket">
            <div onClick={() => { this.handleProductClick(item) }} className="basket-image">
              <Img src={item.imageUrl} alt='Product' unloader={<img src={PlaceholderImage} alt='Product' />} />
            </div>
            <div onClick={() => { this.handleProductClick(item) }} className="basket-text">
              <span>{item.title}</span>
              <span className="basket-text-extra">
                Quantity: {item.amount}
              </span>
            </div>
          </Menu.Item>
        )
      })}

      {this.props.basket.historyItems.length <= 0 &&
        <Menu.Item>
          <div className="basket-empty-cart-message">You have not pruchased any products yet</div>
        </Menu.Item>
      }
    </Menu>;

    return menu;
  }

  render() {
    return (
      <OutsideClickHandler disabled={!this.state.historyOpen} onOutsideClick={() => { this.toggleDropDown(true) }}>
        <Dropdown
          arrow={true}
          getPopupContainer={() => document.getElementById('basket-history-dropdown-container')}
          visible={this.state.historyOpen}
          className="history-basket"
          overlayClassName="shopping-basket-dropdown"
          placement="bottomRight"
          trigger={['click']}
          overlay={this.renderHistoryDropdown}>
          <Button onClick={() => { this.toggleDropDown() }} size="large" type="primary" shape="round" icon={<UserOutlined />}>
            <Badge count={sumBy(this.props.basket.historyItems, i => i.amount)}></Badge>
          </Button>
        </Dropdown>
        <div id="basket-history-dropdown-container"></div>
      </OutsideClickHandler>
    )
  }
}

PurchaseHistoryIndex.contextTypes = {
  router: PropTypes.object
}

export default PurchaseHistoryIndex;