import React, { Component } from 'react';
import { Spin, Layout } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';

class Loader extends Component {
  render() {
    const { Content } = Layout;
    return (
      <Layout>
        <Content className="loading-container">
          <Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 65 }} spin />} />
        </Content>
      </Layout>
    );
  }
}

export default Loader;
