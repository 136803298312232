import React, { Component } from 'react';
import { Button } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

import './button.css';

class ResetButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  handleClick = () => {
    this.props.onAddNotification('Engine reset', 'You are now a new user!', 'info')
    this.props.onReset();
  }

  render() {
    return (
      <Button onClick={this.handleClick} size="large" type="text" icon={<HistoryOutlined />}>Reset Engine</Button>
    )
  }
}

export default ResetButton;
