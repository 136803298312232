import React, { Component } from 'react';
import { Row, Col, Button, Tag, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import FacetFormat from '../../utils/facetFormat';
import FacetItem from './FacetItem';

import './Facets.css';

class Facets extends Component {
  handleFacetClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    var { name, value, checked } = event.target;
    var { selectedFacets } = this.props;

    if (selectedFacets[name] && !checked && selectedFacets[name].includes(value)) {
      selectedFacets[name].splice(selectedFacets[name].indexOf(value), 1)
      if (selectedFacets[name].length === 0) {
        delete selectedFacets[name];
      }
    }

    if (selectedFacets[name] && checked && !selectedFacets[name].includes(value)) {
      selectedFacets[name].push(value);
    } else if (!selectedFacets[name] && checked) {
      selectedFacets[name] = [value];
    }

    this.props.onSelectedFacetsChange(selectedFacets);
  }

  handleRangeFacet = (facet) => {
    this.props.onSelectedFacetsChange(facet);
  }

  sortFacets(config, results, selectedFacets) {
    if (results && results.results.facets.length > 0) {
      var sortedFacetsObject = {};
      for (const filter of config.filters) {
        if (filter.type === 'string') { // if string facet
          FacetFormat.processStringFacet(filter, results.results, selectedFacets, sortedFacetsObject)
        } else if (filter.type === 'numberRange') { // if number facet
          FacetFormat.processNumberFacet(filter, results.results, selectedFacets, sortedFacetsObject)
        }
      }

      return sortedFacetsObject;
    }
  }

  clearFilters = (e) => {
    e.preventDefault();
    this.props.onSelectedFacetsChange({})
  }

  _renderFacets = (facetsArray) => {
    let filtersWidth = 6;

    if (this.props.layout.filters === 'left') { filtersWidth = 24; }

    return (
      <React.Fragment>
        <Row className="facets-row">
          {
            facetsArray.map((facet) => {
              return (
                <Col className="facet-col" lg={filtersWidth} md={24} sm={24} xs={24} key={facet.requestParameter}>
                  <FacetItem sortedFacet={facet.sortedFacet} filter={facet.filter} requestParameter={facet.requestParameter} onRangeFacet={this.handleRangeFacet} onSelectedFacets={this.handleFacetClick} />
                </Col>
              )
            })
          }
        </Row>
      </React.Fragment>
    )
  }

  _renderActiveList = () => {
    const { selectedFacets } = this.props;
    let filters = [];

    if (!selectedFacets || (selectedFacets && Object.keys(selectedFacets).length === 0)) { return null; }

    for (let key in selectedFacets) {
      let facet = selectedFacets[key];

      if (!Array.isArray(facet)) {
        let minMax = [];
        for (let k in facet) {
          minMax.push(facet[k])
        }

        filters.push(<Tag key={Math.random()}>{`${key}: ${minMax.join('-')}`}</Tag>)
      }

      if (Array.isArray(facet)) {
        facet.forEach(item => { filters.push(<Tag key={Math.random()}>{item}</Tag>) })
      }
    }

    return (
      <Row>
        <Col className="facets-active-col" span={24}>
          <span className='facets-active-title'>Active filters:</span>
          {filters}
          <Tooltip placement="left" title="Clear all filters">
            <Button type="text" className="active-filters-clear-btn" onClick={this.clearFilters} icon={<CloseOutlined />}></Button>
          </Tooltip>
        </Col>
      </Row>
    )
  }

  _renderClearFilters = () => {
    return this.props.layout.filters === 'left' ? <Row>
      <Col className="clear-filter-col" span={24}>
        <Button block={true} onClick={this.clearFilters} icon={<CloseOutlined />}>Clear all filters</Button>
      </Col>
    </Row> : null
  }

  render() {
    const { results, selectedFacets, config } = this.props;
    const sortedFacets = this.sortFacets(config, results, selectedFacets);
    let classname = 'facets-container';
    let facetWidth = 24;

    var facetsArray = [];
    sortedFacets && Object.keys(sortedFacets).forEach((requestParameter) => {
      var sortedFacet = sortedFacets[requestParameter];
      var filter = config.filters.find(f => { return f.requestParameter === requestParameter || f.name === requestParameter });
      if ((Array.isArray(sortedFacet) && sortedFacet.length > 0) || filter.type === 'numberRange') {
        facetsArray.push({ sortedFacet: sortedFacet, filter: filter, requestParameter: requestParameter })
      }
    })

    if (this.props.layout.filters === 'left') {
      classname = 'facets-container left';
      facetWidth = 6;
    }

    if (facetsArray.length === 0) {
      return null;
    }

    return (
      <Col lg={facetWidth} md={24} sm={24} xs={24} className={classname}>
        {selectedFacets && Object.keys(selectedFacets).length > 0 ? this._renderClearFilters() : null}
        <div className="facets-active-list">{this.props.layout.filters === 'top' ? this._renderActiveList() : null}</div>
        {this.props.showFilters ? this._renderFacets(facetsArray) : null}
      </Col>
    )
  }
}

export default Facets;
