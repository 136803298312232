import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Dropdown, Menu, Modal, Tooltip } from 'antd';
import { ShoppingCartOutlined, MenuOutlined, DollarOutlined, StarFilled } from '@ant-design/icons';
import Img from 'react-image';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import DiagnosticItemInfoTable from '../diagnostic/diagnostic-item-info-table/DiagnosticItemInfoTable';

import BasketTool from '../../utils/basketTool';
import Events from '../../utils/events';

import './ResultItem.css';
import PlaceholderImage from '../../images/placeholder.png';

import { initializeProduct } from '../../utils/productInitialize'

const defaultMaxDecimalNumbers = 5;
const customAttributeNameRegex = /^([d|D]\.)?([^\[]+)\[?(\d{1,2})?/;

class ResultItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {},
      diagnosticItemModalVisible: false
    }
  }

  componentWillMount() {
    const { item, config } = this.props;
    let stateItem = initializeProduct(item, config);

    this.setState({
      item: stateItem
    })
  }

  handleAddToCartClick = () => {
    const { config, onBasketChange } = this.props;
    const { item } = this.state;

    BasketTool.addToBasket(item, config.name, onBasketChange);
    let event = Events.buildEventObj(item.id, config.addToCartEventType);
    Events.send([event], config.url);
    this.props.onAddNotification('Product added to cart', `You have added ${this.state.item.title} to cart`, 'success')
  }

  handlePurchaseClick = () => {
    const { config, onBasketChange } = this.props;
    const { item } = this.state;

    BasketTool.addToPurchaseHistory(item, config.name, onBasketChange);
    let event = Events.buildEventObj(item.id, config.purchaseEventType);
    Events.send([event], config.url);
    this.props.onAddNotification('Purchase completed', `You have bought ${this.state.item.title}`, 'success')
  }

  handleProductClick = (e) => {
    const { onItemSelected, diagnosticSettings } = this.props;

    if (e && diagnosticSettings.diagnosticModeActive) {
      if (e.ctrlKey === true && e.shiftKey === false) {
        e.preventDefault()
        onItemSelected(this.props.item);
      }
      else if (e.ctrlKey === true && e.shiftKey === true) {
        e.preventDefault()
        this.setState({ diagnosticItemModalVisible: true });
      }
    }
  }

  sendEvent(eventType) {
    const { config } = this.props;
    let event = Events.buildEventObj(this.state.item.id, eventType);
    return Events.send([event], config.url);
  }

  truncateString(str, num) {
    if (str && str.length <= num) {
      return str
    }
    return str ? str.slice(0, num) + '...' : '';
  }

  renderContextMenuItems = () => {
    const { onItemSelected, config, onAddNotification, item } = this.props;
    const { SubMenu } = Menu;
    const diagnosticObject = this.getDiagnosticDataIfExist();
    return <Menu>
      <Menu.Item key="1" onClick={() => { onItemSelected(item) }}>Toggle select</Menu.Item>
      {diagnosticObject && <Menu.Item key="2" onClick={() => { this.setState({ diagnosticItemModalVisible: true }) }}>View item data</Menu.Item>}
      <SubMenu key="3" title="Send events">
        <Menu.Item key="1" onClick={() => { this.sendEvent(config.addToCartEventType).then(() => { onAddNotification("Event sent", "Add to cart event was sent", "success") }) }}>Add to cart event</Menu.Item>
        <Menu.Item key="2" onClick={() => { this.sendEvent(config.clickEventType).then(() => { onAddNotification("Event sent", "Click event was sent", "success") }) }}>Click event</Menu.Item>
        <Menu.Item key="3" onClick={() => { this.sendEvent(config.purchaseEventType).then(() => { onAddNotification("Event sent", "Purchase event was sent", "success") }) }}>Purchase event</Menu.Item>
      </SubMenu>
    </Menu>
  }

  getCustomCardAttributeValue = (attribute) => {
    const [_, diagnosticPart, attributeName, decimalNumber] = attribute.match(customAttributeNameRegex) || [];

    if (!diagnosticPart) {
      const attributeExist = this.props.item.attributes.find(a => a.name === attribute);
      if (!attributeExist) {
        return undefined;
      }
      const attributeData = attributeExist.values;
      return Array.isArray(attributeData) ? attributeData.join(", ") : attributeData;
    }

    const diagnosticObject = this.getDiagnosticDataIfExist();
    if (!diagnosticObject) {
      return undefined;
    }
    const attributeValue = diagnosticObject[attributeName];
    if ((typeof attributeValue !== "number") || Number.isSafeInteger(attributeValue)) {
      return attributeValue;
    }
    const attributeValueNumber = parseFloat(attributeValue);
    if (decimalNumber) {
      return attributeValueNumber.toFixed(decimalNumber);
    }
    return (Math.round(attributeValueNumber * (10 ** defaultMaxDecimalNumbers)) / (10 ** defaultMaxDecimalNumbers));
  }

  renderCustomCardAttributes = () => (
      this.props.diagnosticSettings.customCardAttributes.map(({attribute}) => {
        const attributeData = this.getCustomCardAttributeValue(attribute);

        return (
            <div className="product-custom-attribute" key={Math.random()}>
              <div className="product-custom-attribute-name">{attribute}</div>
              <div className="product-custom-attribute-value">{attributeData === undefined ? 'N/A' : String(attributeData)}</div>
            </div>
        );
      })
  )

  getContextColor = () => {
    const { contextColors } = this.props;
    const diagnosticObject = this.getDiagnosticDataIfExist();
    if (contextColors && diagnosticObject && diagnosticObject.container) {
      return contextColors.find(c => c.id === diagnosticObject.container);
    }
    return null;
  }

  getDiagnosticDataIfExist() {
    let diagnosticData = this.props.item.attributes.find(a => a.name === "Loop54Diagnostics");
    return diagnosticData ? diagnosticData.values[0] : null;
  }

  handleCopyIdClick = (e) => {
    e.preventDefault();
    this.props.onAddNotification("Copied", "Entity id were copied to your clipboard", 'info');
  }

  render() {
    const { diagnosticSettings, multiSelectedItems } = this.props;
    const { item } = this.state;
    let placeholderImage = <img src={PlaceholderImage} className="ui image product__image placeholder" alt='Product' onClick={this.handleProductClick} />
    let contextInfo = this.getContextColor();
    return (
      <React.Fragment>
        <Card
          className={classnames(
            multiSelectedItems && diagnosticSettings.diagnosticModeActive && multiSelectedItems.find(i => i.id === item.id) ? 'product-selected' : null,
            contextInfo && diagnosticSettings.diagnosticModeActive ? 'product-contains-context' : null
          )}>
          {diagnosticSettings.diagnosticModeActive &&
            <Dropdown overlay={this.renderContextMenuItems} trigger={['click']}>
              <Button className="product-context-menu-btn" type="text"><MenuOutlined /></Button>
            </Dropdown>}

          {contextInfo && diagnosticSettings.diagnosticModeActive &&
            <div className="context-info-tag">
              <Tooltip title={`Container probability score: ${contextInfo.probability}`}>
                <span>Rank: {contextInfo.rank} - Id: {contextInfo.id} {item.diagnostics !== null && item.diagnostics.matchGroup === "undefined" ? <StarFilled /> : ''}</span>
              </Tooltip>
              <div style={{ background: `linear-gradient(to bottom, ${contextInfo.color} 0%,#ffffff 100%)` }} className="product-context-background"></div>
            </div>
          }
          {item.sale &&
            <div className="product-sale-tag">
              Sale
            </div>
          }
          {item.new &&
            <div className="product-new-tag">
              New
            </div>
          }
          <div className="product-content-top">
            <Link to={item.productLink} onClick={this.handleProductClick} className="product-image-container">
              <Img className="ui image product__image" src={item.imageUrl} alt='Product' loader={placeholderImage} unloader={placeholderImage} />
            </Link>
            {diagnosticSettings.diagnosticModeActive &&
              <Tooltip title={'Click to copy id'} placement="left">
                <CopyToClipboard text={item.id}>
                  <div onClick={this.handleCopyIdClick} className="product-id">
                    {item.id}
                  </div>
                </CopyToClipboard>
              </Tooltip>
            }
            {item.brand &&
              <div className="product-brand">
                {item.brand}
              </div>
            }

            <Link
              to={item.productLink}
              onClick={this.handleProductClick}
              className="product-title"
              {...(diagnosticSettings.diagnosticModeActive && {title: item.title})}
            >
              {item.title}
            </Link>

            <p className="product-description">{this.truncateString(item.description, 150)}</p>

            {diagnosticSettings.diagnosticModeActive && <div className="product-custom-attributes">{this.renderCustomCardAttributes()}</div>}
          </div>
          <div className="product-content-bottom">
            <div className="product-price">
              {item.sale && item.salePrice ? (
                <React.Fragment>
                  <strike className="item-price-sale-original">{item.price}</strike> <span className="product-sales-price">{item.salePrice}</span>
                </React.Fragment>
              ) : (
                item.price
              )}
            </div>
            <div className="product-btn-container">
              <Button type="primary" onClick={this.handlePurchaseClick} className='product-purchase-btn'>
                <DollarOutlined /><span className="add-cart-text">Purchase</span>
              </Button>
              <Button type="primary" onClick={this.handleAddToCartClick} className='product-add-cart-btn'>
                <ShoppingCartOutlined />
              </Button>
            </div>
          </div>
        </Card>

        <Modal destroyOnClose={true}
          width="60vw"
          bodyStyle={{ padding: 0 }}
          footer={null}
          style={{ top: 30 }}
          title={`Item Id: ${item.id} -- ${item.title}`}
          visible={this.state.diagnosticItemModalVisible}
          onOk={() => { this.setState({ diagnosticItemModalVisible: false }) }}
          onCancel={() => { this.setState({ diagnosticItemModalVisible: false }) }}>
          <DiagnosticItemInfoTable item={this.props.item}></DiagnosticItemInfoTable>
        </Modal>
      </React.Fragment>
    );
  }
}

ResultItem.contextTypes = {
  router: PropTypes.object
}

export default ResultItem;
