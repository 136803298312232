import React, { Component } from 'react';
import { Select } from 'antd';

import './index.css';

class SortIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSort: null
    }
  }

  componentWillMount() {
    let { sortAttribute, sortDescending } = this.props;
    let defaultSort;

    if(sortAttribute !== 'relevance') {
      defaultSort = sortAttribute;
      defaultSort += sortDescending ? '>DESC' : '';
    }

    this.setState({
      defaultSort: defaultSort
    })
  }

  componentWillReceiveProps(nextProps) {
    let { sortAttribute, sortDescending } = nextProps;
    let defaultSort;

    if(sortAttribute !== 'relevance') {
      defaultSort = sortAttribute;
      defaultSort += sortDescending ? '>DESC' : '';
    }

    this.setState({
      defaultSort: defaultSort
    })
  }

  render() {
    const { config, resultsCount } = this.props;

    if(!resultsCount || resultsCount === 0) {
      return null;
    }

    let options = [
      {
        label: 'Relevance',
        value: 'relevance>DESC'
      },
      {
        label: 'Name A-Z',
        value: config.productTitleAttribute
      },
      {
        label: 'Name Z-A',
        value: config.productTitleAttribute+'>DESC'
      }
    ]

    if(config.productPriceAttribute) {
      options.push({
        label: 'Price 0-99',
        value: config.productPriceAttribute
      },
      {
        label: 'Price 99-0',
        value: config.productPriceAttribute+'>DESC'
      })
    }

    return (
      <Select defaultValue={this.state.defaultSort} className="sortby-dropdown" placeholder="Sort by" style={{ width: 120 }} options={options} onChange={this.props.onSortChange}></Select>
    )
  }
}

export default SortIndex;