import React, { Component } from 'react';
import { Col } from 'antd';

class PaginationNumbers extends Component {
  render() {
    let pages = [];
    let { currentPage, maxPage, onPageChange } = this.props;

    if (maxPage > 1) {
      if (maxPage > 4 && currentPage > 5) {
        pages.push(<a className="page-num" href="?p=1" onClick={onPageChange} data-page="1" key={Math.random()}><span>1</span></a>)
        pages.push(<span className="page-num page-dots" key={Math.random()}>...</span>)
      } else if (currentPage === 5) {
        pages.push(<a className="page-num" href="?p=1" onClick={onPageChange} data-page="1" key={Math.random()}><span>1</span></a>)
      }

      for (var i = 1; i <= maxPage; i++) {
        if (i === currentPage) {
          pages.push(<span className="page-num page-current" key={Math.random()}>{i}</span>)
        } else if (i >= currentPage - 3 && i <= currentPage + 3) {
          pages.push(<a className="page-num" href={"?p=" + i} onClick={onPageChange} data-page={i} key={Math.random()}><span>{i}</span></a>)
        }
      }

      if (currentPage < maxPage - 4) {
        pages.push(<span className="page-num page-dots" key={Math.random()}>...</span>)
        pages.push(<a className="page-num" href={"?p=" + maxPage} onClick={onPageChange} data-page={maxPage} key={Math.random()}><span>{maxPage}</span></a>)
      } else if (currentPage === maxPage - 4) {
        pages.push(<a className="page-num" href={"?p=" + maxPage} onClick={onPageChange} data-page={maxPage} key={Math.random()}><span>{maxPage}</span></a>)
      }
    }

    return (
      <Col span={24}>
        <div className="pagination-container">
          <div className="pages-container">
            {pages}
          </div>
        </div>
      </Col>
    )
  }
}

export default PaginationNumbers;
