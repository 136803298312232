import React, { Component } from 'react';
import { Button, Dropdown, Menu, Badge, Tooltip } from 'antd';
import { ShoppingCartOutlined, CloseOutlined, DeleteFilled } from '@ant-design/icons';
import { sumBy } from 'lodash';
import Img from 'react-image';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import Events from '../../utils/events';
import basketTool from '../../utils/basketTool';
import loop54Client from '../../utils/loop54Client';
import handleDropdownOpenState from '../../utils/dropdownTool'
import BasketCarousel from './basketCarousel';

import PlaceholderImage from '../../images/placeholder-small.png';

import './index.css';

class ShoppingbasketIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basketOpen: false,
      recItems: []
    }
  }

  toggleDropDown(forceShut = false) {
    const value = forceShut ? false : !this.state.basketOpen;
    this.setState({ basketOpen: value });
    handleDropdownOpenState(value);
  }

  componentDidMount() {
    if (!this.state.basketOpen) {
      handleDropdownOpenState(false);
    }
    this.getBasketRecommendations();
  }

  componentDidUpdate(prevProps) {
    if (this.props.basket !== prevProps.basket) {
      this.getBasketRecommendations();
    }
  }

  getBasketRecommendations() {
    let entities = this.props.basket.items.map(i => {
      return {
        type: "Product",
        id: String(i.id)
      }
    });

    var resultOptionsRequest = {
      take: 6
    }

    this.setState({ recItems: [] });
    if (entities.length > 0) {
      loop54Client.getBasketRecommendations(entities, resultOptionsRequest).then(response => {
        this.setState({
          recItems: response.data.results.items
        })
      })
    }
  }

  handleProductClick = (item) => {
    this.toggleDropDown(true);
    this.context.router.history.push(item.productLink);
  }

  handleCheckoutClick = () => {
    const { basket, config, onAddNotification } = this.props;
    let events = [];
    basket.items.forEach(item => {
      for (let index = 0; index < item.amount; index++) {
        events.push(Events.buildEventObj(item.id, config.purchaseEventType));
      }
    });

    Events.send(events, config.url).then(() => {
      var newBask = basketTool.checkoutBasket(config.name);
      this.props.onBasketChange(newBask);
      onAddNotification('Checkout completed', <span>We will find some better products for you next time around</span>, 'success')
    });
  }

  handleRemoveItemFromBasketClick = (item) => {
    const { config, onBasketChange } = this.props;

    basketTool.removeItemFromBasket(config.name, item, onBasketChange)
  }

  renderBasketDropdown = () => {
    const menu = <Menu>
      <div className="basket-header">
        Your cart
        <Button type="text" icon={<CloseOutlined />} onClick={() => this.toggleDropDown()}></Button>
      </div>
      {this.props.basket && this.props.basket.items.map(item => {
        return (
          <Menu.Item key={Math.random()} className="basket">
            <div onClick={() => { this.handleProductClick(item) }} className="basket-image">
              <Img src={item.imageUrl} alt='Product' unloader={<img src={PlaceholderImage} alt='Product' />} />
            </div>
            <div onClick={() => { this.handleProductClick(item) }} className="basket-text">
              <span>{item.title}</span>
              <span className="basket-text-extra">
                Quantity: {item.amount}
              </span>
            </div>
            <Tooltip title="Remove product from cart" placement="left">
              <Button type="text" icon={<DeleteFilled />} onClick={() => this.handleRemoveItemFromBasketClick(item)} className="basket-remove-item-btn"></Button>
            </Tooltip>
          </Menu.Item>
        )
      })}

      {this.props.basket.items.length <= 0 &&
        <Menu.Item>
          <div className="basket-empty-cart-message">Your cart is empty</div>
        </Menu.Item>
      }

      <div className="basket-bottom-area">
        <div className="basket-purchase-area">
          <span className="basket-bottom-area-text">Total products: <b>{sumBy(this.props.basket.items, i => i.amount)}</b></span>
          <Button disabled={this.props.basket.items.length <= 0} onClick={this.handleCheckoutClick} block="true" type="primary" size="large">
            Checkout
          </Button>
          <a className="goto-basket-page-link" href={`/${this.props.config.name}/checkout`}>Goto checkout page</a>
        </div>
        <BasketCarousel items={this.state.recItems} {...this.props}></BasketCarousel>
      </div>
    </Menu>;

    return menu;
  }

  render() {
    return (
      <OutsideClickHandler disabled={!this.state.basketOpen} onOutsideClick={() => { this.toggleDropDown(true) }}>
        <Dropdown
          arrow={true}
          getPopupContainer={() => document.getElementById('basket-dropdown-container')}
          visible={this.state.basketOpen}
          className="shopping-basket"
          overlayClassName="shopping-basket-dropdown"
          placement="bottomRight"
          trigger={['click']}
          overlay={this.renderBasketDropdown}>
          <Button onClick={() => this.toggleDropDown()} size="large" type="primary" shape="round" icon={<ShoppingCartOutlined />}>
            Your cart <Badge count={sumBy(this.props.basket.items, i => i.amount)}></Badge>
          </Button>
        </Dropdown>
        <div id="basket-dropdown-container"></div>
      </OutsideClickHandler>
    )
  }
}

ShoppingbasketIndex.contextTypes = {
  router: PropTypes.object
}

export default ShoppingbasketIndex;