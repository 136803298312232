//The category-dd-active class that is appended to the header is so that when a dropdown is active in the 
//menu we should not shrink the header when user scrolls the main window, causing the dropdown to disappear.

//The body-category-dd-active class is added to the Body so that we can certain elments transparent while dropdown is active for a more focues view.

let dropdownTool = {
  handleDropdownOpenState(open) {
    let headerEl = document.getElementById("demo-header");
    let bodyEl = document.getElementsByTagName("BODY")[0];
    if (open) {
      headerEl.classList.add("category-dd-active");
      bodyEl.classList.add("body-category-dd-active");
    } else {
      headerEl.classList.remove("category-dd-active");
      setTimeout(() => {
        if(!headerEl.classList.contains("category-dd-active")){
          bodyEl.classList.remove("body-category-dd-active");
        }
      }, 200);
    }
  }
}

export default dropdownTool.handleDropdownOpenState;
