import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Input, Button } from 'antd';
import { FolderOutlined, SearchOutlined } from '@ant-design/icons';
import debounce from '../../utils/debounce';
import loop54Client from '../../utils/loop54Client';
import handleResponseError from '../../utils/responseError';

import './SearchField.css';

class SearchFieldIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      value: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerm && nextProps.searchTerm !== this.state.value) {
      this.setState({
        value: nextProps.searchTerm
      })
    }
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  getAutocompleteList = (value) => {
    loop54Client.autoComplete(value).then(response => {
      if (response.status === 200) {
        var items = this.formatResponse(response.data);
        if (this._ismounted) {
          this.setState({
            items: items
          })
        }
      }
    })
      .catch(response => handleResponseError(response));
  }

  formatResponse(data) {
    var ret = [], facets = [];
    ret = data.queries.items.map((x) => {
      return {
        label: x.query,
        value: x.query
      };
    });

    if (data.scopedQuery) {
      facets = data.scopedQuery.scopes.map((x) => {
        return {
          label: data.scopedQuery.query,
          facet: x,
          value: data.scopedQuery.query,
        };
      });
    }

    ret.unshift(...facets);
    ret.map(i => i["identifier"] = i.facet ? i.value + i.facet : i.value)
    return ret;
  }

  renderItem = (item) => ({
    value: item.identifier,
    label: (
      <div className="autoselect-item">
        <span className="autoselect-item-label">{item.label} </span>
        {item.facet ? <span className="autoselect-item-facet"> in <FolderOutlined /><span>{item.facet}</span></span> : ''}
      </div>
    ),
    key: item.identifier
  });

  onSearch = (searchText) => {
    this.setState({ value: searchText })
    if (searchText.length > 0) {
      this.debounceGetAutocompleteList(searchText);
    }
  };

  onSelect = (dataIndex) => {
    this.props.onSearchFieldChange(this.state.items.find(i => i.identifier === dataIndex))
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.props.onSearchFieldChange({ value: this.state.value })
    }
  }

  debounceGetAutocompleteList = debounce((e) => this.getAutocompleteList(e));

  render() {
    return (
      <div className="main-search">
        <div className='addon-before'>Search Product</div>
        <AutoComplete
          className='searchfield-autocomplete'
          value={this.state.value}
          options={this.state.items.map((i) => this.renderItem(i))}
          onSelect={this.onSelect}
          onSearch={this.onSearch}
          dropdownClassName="searchfield-autocomplete-dropdown"
        >
          <Input
            id='searchfield-input'
            size="large"
            placeholder="Search among thousands of products"
            onKeyDown={this.handleKeyDown}
          />
        </AutoComplete>
        <div className="addon-after">
          <Button type="text" onClick={() => { this.props.onSearchFieldChange({ value: this.state.value }) }} icon={<SearchOutlined />} />
        </div>
      </div>
    )
  }
}

SearchFieldIndex.contextTypes = {
  router: PropTypes.object
};

export default SearchFieldIndex;
