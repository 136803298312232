import loop54Client from './loop54Client';
import handleResponseError from './responseError';

let events = {
  send(events, url) {
    return loop54Client.createEvents(events).then(response => {
      events.forEach(event => {
        console.log("Successfully sent event: {type: " + event.type + ", id: " + event.entity.id + "}")
      });
    })
    .catch(response => handleResponseError(response))
  },
  
  buildEventObj(id, eventType) {
    return {
      type: eventType,
      entity: {
        type: 'Product',
        id: id,
      }
    }
  }
}

export default events;
