import React, { Component } from 'react';
import { Layout } from 'antd';
import { TwitterSquareFilled, LinkedinFilled, FacebookFilled } from '@ant-design/icons';

import './index.css';

class Footer extends Component {

  render() {
    const { Footer } = Layout;
    return (
      <Footer className="demo-footer">
        <div className="demo-footer-container">
          <div>© Copyright {new Date().getFullYear()}</div>
          <a className="privacy-policy" href="https://www.loop54.com/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>
          <div className="social-icons">
            <a href="https://twitter.com/loopfiftyfour" target="_blank" rel="noopener noreferrer"><TwitterSquareFilled style={{ fontSize: 20 }} /></a>
            <a href="https://www.linkedin.com/company/loop54" target="_blank" rel="noopener noreferrer"><LinkedinFilled style={{ fontSize: 20 }} /></a>
            <a href="https://www.facebook.com/loopfiftyfour/" target="_blank" rel="noopener noreferrer"><FacebookFilled style={{ fontSize: 20 }} /></a>
          </div>
        </div>
      </Footer>
    )
  }
}

export default Footer;
