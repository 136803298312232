let getSelectedFacets = (response, facets) => {
  facets.map((facet) => {
    return response.include(facet)
  })
}

let searchFormat = {
  request(item, selectedFacets, {config, page, sortAttribute, sortDescending}) {
    var req = {
        take: config.continousScrolling ? config.directResultsPageSize * (page + 1) : config.directResultsPageSize,
        skip: config.continousScrolling ? 0 : config.directResultsPageSize * page,
        sortBy:[],
        facets:[],
        relatedResultsOptions:{
          take: config.continousScrolling ? config.recommendedResultsPageSize * (page + 1) : config.recommendedResultsPageSize,
          skip: config.continousScrolling ? 0 : config.recommendedResultsPageSize * page
        },
        relatedQueriesOptions:{
          take: 5,
          skip:0,
        }
    };

    if(config.filters){
      config.filters.forEach(filter => {
        if (filter.type === "numberRange"){
          let attribute = filter.responseParameter[0].replace(".MinValue","").replace(".MaxValue","");
          req.facets.push(
            {
              name:filter.name,
              attributeName:attribute,
              type:"range",
              selected:selectedFacets[filter.name] ? {
                min:selectedFacets[filter.name].min,
                max:selectedFacets[filter.name].max
              } : null
            }
          );
        }else {
          req.facets.push(
            {
              name:filter.name,
              attributeName:filter.responseParameter,
              type:"distinct",
              selected: selectedFacets && selectedFacets[filter.requestParameter] && selectedFacets[filter.requestParameter].length > 0 ? selectedFacets[filter.requestParameter] : []
            }
          );
        }
      })
    }

    if (sortAttribute) {
          const sortBy = {
              type: sortAttribute === "relevance" ? "relevance" : "attribute",
              order: sortDescending ? "desc" : "asc"
          };
          if (sortAttribute !== "relevance") {
              sortBy.attributeName = sortAttribute;
          }
          req.sortBy.push(sortBy);
    }

    return req;
  },

  response(response) {
    if(!response.status === 200) { return {error: "Something went wrong, try again"} }

    return response.data;
  },

  checkAvailableFacets(selectedFacets, response) {
    for(var key in selectedFacets) {
      if(response[key]) {
        selectedFacets = getSelectedFacets(response[key], selectedFacets[key]);
      } else {
        delete selectedFacets[key];
      }
    }
    return selectedFacets;
  }
}

export default searchFormat;
