import React, { Component } from 'react';
import Footer from '../footer';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return <div className='no-page'>
        <div className="no-page-message-logo"></div>
        <div className="no-page-message-wrapper">
          <div className="no-page-message-modal">
            <h1>A JavaScript Error Occurred in the Main Process</h1>
            <details style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
              <summary>Click here to view more details about the error</summary>
              <span style={{ fontWeight: 800 }}>{this.state.error && this.state.error.toString()}</span>
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        </div>
        <Footer></Footer>
      </div>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
