import React, { Component } from 'react';
import { Layout } from 'antd';

import Footer from '../components/footer';

import './Index.css';

import welcomeImage from '../images/intro.svg';
import Header from '../components/header/Header';

class Index extends Component {
  render() {
    const { Content } = Layout;
   
   
    return (
      <Layout>
        <Header {...this.props} />
        <Content>
          <div className="welcome-message">
            <img alt="Products" src={welcomeImage}></img>
            <h1>Infinity eCommerce demo software</h1>
            <p>
              With minimal data or human intervention, Infinity gives visitors one-to-one personalisation. Intuitively anticipate customer needs, exactly when they need it.
            </p>
          </div>
        </Content>
        <Footer></Footer>
      </Layout>
    );
  }
}

export default Index;
