import React, { Component } from 'react';
import Footer from './components/footer';

import './Loop54Splash.css';

class Loop54Splash extends Component {
  render() {
    return (
      <div className='no-page'>
        <div className="no-page-message-logo"></div>
        <div className="no-page-message-wrapper">
          <div className="no-page-message-modal">
            <h1>Hi there!</h1>
            <p>You need a special link to browse our demo</p>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Loop54Splash;
