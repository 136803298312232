import React from 'react';
import UrlTool from '../../utils/urlTool';
import { Alert, Row, Col } from 'antd';

import './index.css';

const MessageIndex = (query, makesSense, resultsLength, spellingSuggestions, router) => {
  let header, message, type = 'info';

  let redirect = (suggestion) => {
    UrlTool.setQuery('q', suggestion, router)
  }

  if (!makesSense) {
    type = 'error';

    if (resultsLength > 0) {
      type = 'warning';
      message = 'The results below are approximate';
    }

    if (spellingSuggestions && spellingSuggestions.length > 0) {
      type = 'warning';
      message = (<span>Did you mean to search for: <a href="#redirect" onClick={(e) => { e.preventDefault(); redirect(spellingSuggestions.items[0].query) }}>{spellingSuggestions.items[0].query}</a>?</span>);
    }

    return (
      <Row>
        <Col className="message-col" span={24}>
          <Alert message={header} description={<div>We did not understand the query <b>{query}</b>. {message}</div>} type={type} />
        </Col>
      </Row>
    )
  }
}

export default MessageIndex;
