import React, { Component } from 'react';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import DropdownTool from '../../utils/dropdownTool'

class MenuCategoryMultiLevelDropdown extends Component {
  getMenuItems() {
    const { mappedCategories, demoName, categoryTitleInDemo, subItemsMaxLength } = this.props;
    return <div>
      <Menu>
        {mappedCategories.map(cat => {
          return <li key={cat.hash}>
            <a className="menu-multi-level-dropdown-heading" href={`/${demoName}/${categoryTitleInDemo.toLowerCase()}/${cat.hash}`}>{cat.name}</a>
            <div className="menu-multi-level-dropdown-subitems">
              {cat.subCategories && cat.subCategories.slice(0, subItemsMaxLength).map(subCat => {
                return <a key={subCat.hash} href={`/${demoName}/${categoryTitleInDemo.toLowerCase()}/${subCat.hash}`}>{subCat.name}</a>
              })}

              {cat.subCategories && cat.hash && cat.subCategories.length > subItemsMaxLength &&
                <a className="category-dropdown-view-all" href={`/${demoName}/${categoryTitleInDemo.toLowerCase()}/${cat.hash}`}>View all categories for {cat.name}</a>}
            </div>
          </li>
        })}
      </Menu>
    </div>
  }

  handleOnShowState = (open) => {
    DropdownTool(open);
  }

  render() {
    return (
      <Dropdown overlayClassName="menu-multi-level-dropdown" trigger="click" onVisibleChange={this.handleOnShowState} getPopupContainer={() => this.props.dropDownMenuContainerRef} key={Math.random()} overlay={this.getMenuItems()}>
        <Tooltip title="More categories" placement="right">
          <Button size="large" type="text" style={{ fontSize: 12 }}><span className="text-dot"></span></Button>
        </Tooltip>
      </Dropdown>
    );
  }
}

export default MenuCategoryMultiLevelDropdown;
