import React, { Component } from 'react';
import { Row, Col, Menu, Dropdown, Button } from 'antd';
import MenuCategoryDropdown from './menuCategoryDropdown';
import MenuCategoryDrawerMobile from './menuCategoryDrawerMobile';
import MenuCategoryMultiLevelDropdown from './menuCategoryMultiLevelDropdown';
import MenuBrandDropdown from './menuBrandDropdown';
import MenuNonStandardCategoryDropdown from './menuNonStandardCategoryDropdown';

import './index.css';

function flatMapObj(dataToFlatten) {
  let result = [];
  for (var objKey in dataToFlatten) {
    let item = dataToFlatten[objKey];

    if (item.level2) {
      item.subCategories = flatMapObj(item.level2);
    }

    item.name = objKey;
    result.push(item);
  }

  return result;
}

function getNonStandardCategories(mappedEntities) {
  return mappedEntities.filter(e => !e.isBrandEntity && !e.isCategoriesEntity)
}

function calculateDefaultNumberOfMenuItems(categoryEntity) {
  let maxCharBuffer = 100, currentCharBuffer = 0;

  for (let index = 0; index < categoryEntity.subCategories.length; index++) {
    currentCharBuffer += categoryEntity.subCategories[index].name.length;
    if (currentCharBuffer >= maxCharBuffer)
      return index;
  }

  return categoryEntity.subCategories.length; // Return total length if maxCharBuffer is not reached
}

class MenuIndex extends Component {
  constructor(props) {
    super(props);
    this.state = { dropDownMenuContainerRef: null };
  }

  renderMenuItemCategoryDropdown = (category, titleInDemo) => {
    return <MenuCategoryDropdown dropDownMenuContainerRef={this.state.dropDownMenuContainerRef} key={Math.random()} mappedCategory={category} demoName={this.props.config.name} subItemsMaxLength={30} categoryTitleInDemo={titleInDemo}></MenuCategoryDropdown>
  }

  renderMenuItemCategoriesMultiLevelDropdown = (categories, titleInDemo) => {
    if (!categories || categories.length <= 0) {
      return null;
    }
    return <MenuCategoryMultiLevelDropdown dropDownMenuContainerRef={this.state.dropDownMenuContainerRef} mappedCategories={categories} demoName={this.props.config.name} subItemsMaxLength={30} categoryTitleInDemo={titleInDemo}></MenuCategoryMultiLevelDropdown>
  }

  renderMainCategoriesMobileMenu = (mappedCategories, titleInDemo) => {
    return <MenuCategoryDrawerMobile demoName={this.props.config.name} mappedCategories={mappedCategories} categoryTitleInDemo={titleInDemo}></MenuCategoryDrawerMobile>
  }

  renderMenuItemBrandDropdown = (brand, titleInDemo) => {
    return <MenuBrandDropdown dropDownMenuContainerRef={this.state.dropDownMenuContainerRef} key={Math.random()} mappedBrand={brand} demoName={this.props.config.name} categoryTitleInDemo={titleInDemo}></MenuBrandDropdown>
  }

  renderNonStandardizedCategories = (category) => {
    return <MenuNonStandardCategoryDropdown dropDownMenuContainerRef={this.state.dropDownMenuContainerRef} key={Math.random()} category={category} demoName={this.props.config.name} subItemsMaxLength={30}></MenuNonStandardCategoryDropdown>
  }

  wrapInDropdownNonStandardEntityDropdowns = (categories) => {
    if (!categories.length) return null;

    if (categories.length === 1) {
      return this.renderNonStandardizedCategories(categories[0]);
    }

    const menu = (
      <Menu>
        {categories.map((category, index) => (
          <Menu.Item key={index}>
            {this.renderNonStandardizedCategories(category)}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button size="large" type="text">
          Shop By...
        </Button>
      </Dropdown>
    );
  };

  render() {
    let { entities } = this.props;
    if (!entities || entities.length <= 0) {
      return null;
    }

    let mappedEntities = entities.map((entity, index) => {
      return {
        titleInDemo: entity.titleInDemo,
        subCategories: flatMapObj(entity.items),
        isCategoriesEntity: index === 0,
        isBrandEntity: index === 1
      }
    });

    //Depending on how many entities there are in the config we want to prevent render to many items in the main menu.
    const defaultNumberOfMenuItems = calculateDefaultNumberOfMenuItems(mappedEntities.find(e => e.isCategoriesEntity));
    let finalMenuItemsCount = defaultNumberOfMenuItems - getNonStandardCategories(mappedEntities).length;
    let numberOfMenuItems = finalMenuItemsCount > 0 ? finalMenuItemsCount : defaultNumberOfMenuItems;

    let catEntity = mappedEntities.find(e => e.isCategoriesEntity);
    let mainCategoriesDropdowns, mainCategoriesGroupedDropdown, mainCategoriesMobileMenu;
    if (catEntity) {
      mainCategoriesDropdowns = catEntity.subCategories.slice(0, numberOfMenuItems).map(subCategory => this.renderMenuItemCategoryDropdown(subCategory, catEntity.titleInDemo));
      mainCategoriesGroupedDropdown = this.renderMenuItemCategoriesMultiLevelDropdown(catEntity.subCategories.slice(numberOfMenuItems, catEntity.subCategories.length), catEntity.titleInDemo);
      mainCategoriesMobileMenu = this.renderMainCategoriesMobileMenu(catEntity.subCategories, catEntity.titleInDemo);
    }

    let brandEntity = mappedEntities.find(e => e.isBrandEntity);
    let brandsDropdown;
    if (brandEntity) {
      brandsDropdown = this.renderMenuItemBrandDropdown(brandEntity, brandEntity.titleInDemo);
    }

    let nonStandardEntities = getNonStandardCategories(mappedEntities);
    let nonStandardEntityDropdowns = this.wrapInDropdownNonStandardEntityDropdowns(nonStandardEntities);

    return (
      <React.Fragment>
        {mainCategoriesMobileMenu}
        <Row className="category-listnings">
          <Col className="category-listnings-items-col" span={24}>
            <div className="category-listnings-main">
              {mainCategoriesDropdowns}
              {mainCategoriesGroupedDropdown}
              <span style={{ marginLeft: "auto" }}>
                {nonStandardEntityDropdowns}
                {brandsDropdown}
              </span>
            </div>
            <div ref={ref => !this.state.dropDownMenuContainerRef && this.setState({ dropDownMenuContainerRef: ref })} className="cat-dropdown-menu-container"></div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default MenuIndex;
