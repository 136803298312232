import React, { Component } from 'react';
import { Button, Col, Progress } from 'antd';

class PaginationShowMore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    }
  }

  componentDidUpdate() {
    if(this.props.fetchingResults !== this.state.loading){
      this.setState({
        loading: this.props.fetchingResults
      })
    }
  }

  render() {
    let { currentPage, maxPage, onShowMore, resultsMaxCount, resultsCount } = this.props;

    return (
      <React.Fragment>
        {maxPage > 1 && (currentPage !== maxPage) ?
          <Col span={24}>
            <div className="pagination-container">
              <div className="pagination-progress">
                <p>Showing {resultsCount} of {resultsMaxCount} products</p>
                <Progress showInfo={false} percent={((resultsCount / resultsMaxCount) * 100).toFixed(1)} />
              </div>
              <Button type="primary" size="large" loading={this.state.loading} shape="round" onClick={onShowMore}>Show more</Button>
            </div>
          </Col> : null}
      </React.Fragment>
    )
  }
}

export default PaginationShowMore;
