const SortFacetsByChecked = (a, b) => {
  // Sorting the facets according to
  // first: sort on checked or not
  // second: sort on value (number)
  // third: sort on text
  if(a.selected.toString() === b.selected.toString()) {
    if(a.count === b.count) {
      return (a.item < b.item) ? -1 : (a.item > b.item) ? 1 : 0;
    } else {
      return (a.count > b.count) ? -1 : (a.count < b.count) ? 1 : 0;
    }
  } else {
    return a.selected ? -1 : 1;
  }
}

let facetFormat = {
  processStringFacet(filter, results, selectedFacets, sortedFacetsObject) {
    var facetsExist = results.facets.find(f => f.name === filter.name);
    var facets = facetsExist ? facetsExist.items : [];
    var sortedFacets = {};
    sortedFacets[filter.requestParameter] = [];

    if(facets) { // if there are facets present in the result
      sortedFacets[filter.requestParameter] = facets.map(facet => {
        // check if the facet is checked or not (comparing the local selectedFacets list with the result list)
        facet.selected = selectedFacets[filter.requestParameter] ? selectedFacets[filter.requestParameter].includes(facet.item) : false;
        return facet;
      })

      if(selectedFacets[filter.requestParameter]) {
        // check if the selectedFacet is checked but NOT in the result, this will return a value 0 for that facet, we still want to display it
        selectedFacets[filter.requestParameter].map(name => {
          if(sortedFacets[filter.requestParameter].filter(f => {return f.item === name}).length > 0) { return false }
          sortedFacets[filter.requestParameter].push({ item: name, count: 0, selected: true });
          return false;
        })
      }
      sortedFacets[filter.requestParameter].sort(SortFacetsByChecked) // sort the facets
    } else if(selectedFacets[filter.requestParameter]) {
      sortedFacets[filter.requestParameter] = selectedFacets[filter.requestParameter].map(name => {
        return {item: name, count: 0, selected: true};
      })
      sortedFacets[filter.requestParameter].sort(SortFacetsByChecked);
    }
    sortedFacetsObject[filter.requestParameter] = sortedFacets[filter.requestParameter];
    return sortedFacetsObject;
  },

  processNumberFacet(filter, results, selectedFacets, sortedFacetsObject) {
    var currentMinValue, currentMaxValue, minValue, maxValue;
    var priceRangeFacet = results.facets.find(f => f.name === filter.name);

    if(!priceRangeFacet) return;

    currentMinValue = priceRangeFacet.selectedMin;
    currentMaxValue = priceRangeFacet.selectedMax > 0 ? priceRangeFacet.selectedMax : priceRangeFacet.max;
    minValue = priceRangeFacet.min;
    maxValue = priceRangeFacet.max;
    sortedFacetsObject[filter.name] = {minValue: minValue, maxValue: maxValue, currentMinValue: currentMinValue, currentMaxValue: currentMaxValue, type: filter.type};
    return sortedFacetsObject;
  }
}

export default facetFormat;
