import getClient from 'loop54-js-connector';

var _diagnosticSettings;
var _client;

var loop54Client = {
  setClient(url, diagnosticSettings) {
    _diagnosticSettings = diagnosticSettings;
    let customHeaders;
    if (_diagnosticSettings.diagnosticModeActive) {
      customHeaders = { "Diagnostics": "true" }
    }
    const apiKey = localStorage.getItem("globalApiKey");


    _client = getClient(url, null, apiKey, customHeaders);
  },

  autoComplete(query) {
    return _client.autoComplete(query, _processArgs({}))
  },

  createEvents(events) {
    return _client.createEvents(events, _processArgs({}))
  },

  getEntities(args) {
    return _client.getEntities(_processArgs(args));
  },

  getEntitiesByAttribute(attributeName, attributevalue, args) {
    return _client.getEntitiesByAttribute(attributeName, attributevalue, _processArgs(args));
  },

  getRelatedEntities(entity, args) {
    return _client.getRelatedEntities(entity, _processArgs(args));
  },

  getBasketRecommendations(entities, args) {
    return _client.getBasketRecommendations(entities, _processArgs(args));
  },

  getComplementaryEntities(entity, args) {
    return _client.getComplementaryEntities(entity, _processArgs(args));
  },

  search(query, args) {
    return _client.search(query, _processArgs(args));
  },
  
  getIndexedAttributes() {
    return _client.getIndexedAttributes();
  },

  getIndexedAttributeValues(attributeName, args) {
    return _client.getIndexedAttributeValues(attributeName, _processArgs(args));
  },

  generateNewUserId() {
    return _client.generateNewUserId();
  }
}

function _processArgs(args) {
  if (_diagnosticSettings.diagnosticModeActive && _diagnosticSettings.customDataJson) {
    args["customData"] = _diagnosticSettings.customDataJson;
  }

  return args
}

export default loop54Client;
