const basketLocalStorage = {
  remove: (demoName) => { localStorage.removeItem(demoName + '-basketv2') },
  set: (demoName, data) => { localStorage.setItem(demoName + '-basketv2', JSON.stringify(data)) },
  get: (demoName) => { return JSON.parse(localStorage.getItem(demoName + '-basketv2')) }
};

let basketTool = {
  addToBasket(item, demoName, callback) {
    let basket = handleBasketItems(item, demoName, "items");
    callback(basket);
  },

  addToPurchaseHistory(item, demoName, callback) {
    let basket = handleBasketItems(item, demoName, "historyItems");
    callback(basket);
  },

  getBasket(demoName) {
    let basket = basketLocalStorage.get(demoName);

    if (!basket) { return this.newBasket() }
    return basket;
  },

  emptyBasket(demoName) {
    basketLocalStorage.remove(demoName, this.newBasket);
  },

  removeItemFromBasket(demoName, item, callback) {
    let basket = basketLocalStorage.get(demoName);
    basket.items = basket.items.filter(i => i.id !== item.id);
    basketLocalStorage.set(demoName, basket);
    callback(basket);
  },

  updateBasketItemAmount(item, amount, demoName, callback) {
    let basket = basketLocalStorage.get(demoName);
    let itemToUpdate = basket.items.find(i => i.id === item.id);
    itemToUpdate.amount = amount;
    basketLocalStorage.set(demoName, basket);
    callback(basket);
  },

  checkoutBasket(demoName) {
    let basket = basketLocalStorage.get(demoName);
    basket.historyItems = [...basket.historyItems, ...basket.items];
    basket.items = [];
    basketLocalStorage.set(demoName, basket);
    return basket;
  },

  newBasket() {
    return { items: [], historyItems: [] };
  }
}

function handleBasketItems(item, demoName, basketProperty) {
  let basket = basketLocalStorage.get(demoName);
  if (!basket) { basket = {}; basket.items = []; basket.historyItems = []; }
  let itemIndex = null;

  for (var i = 0; i < basket[basketProperty].length; i++) {
    if (basket[basketProperty][i].id === item.id) {
      itemIndex = i;
    }
  };

  if (itemIndex !== null) {
    basket[basketProperty][itemIndex].amount += item.amount;
  } else {
    basket[basketProperty].push(item);
  }

  basketLocalStorage.set(demoName, basket)
  return basket;
}

export default basketTool;